import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import { CellInputAlert } from "./CellInputAlert";
import { isNullOrEmpty } from "../../../../helpers";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          minWidth: "90px",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
            minWidth: "fit-content"
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

const invertSignal = (value = 0) => {
  return value * -1;
}

export default function SimulatorTableEditable(props) {
  const { state, actions, rows, currency, campaign, valorizacaoBarter } = props;

  useEffect(() => {
    actions.actions.attDiscount(
      campaign,
      state.descontoOfertaEstruturada,
      state.descExalt,
      state.descUtrisha,
      currency
    );
  }, [rows, campaign, state.descontoOfertaEstruturada, state.descExalt, state.descUtrisha, currency, actions.actions])

  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="custom pagination table"
        >
          <TableHead>
            <TableRow
              sx={{
                th: {
                  fontWeight: "bold",
                },
              }}
            >
              <TableCell align="center">Produto</TableCell>
              <TableCell align="center">Última Negociação</TableCell>
              <TableCell align="center">Recomendações</TableCell>
              <TableCell align="center">Sales Input</TableCell>
              <TableCell align="center">Cota</TableCell>
              <TableCell align="center">Preço</TableCell>
              <TableCell align="center">Desconto Categoria Max</TableCell>
              <TableCell align="center">Desconto Categoria</TableCell>
              <TableCell align="center">Desconto Combo Max</TableCell>
              <TableCell align="center">Desconto Combo</TableCell>
              <TableCell align="center">Valor Final Corteva</TableCell>
              <TableCell align="center">Preço proposto pelo Cliente</TableCell>
              <TableCell align="center">Volume</TableCell>
              <TableCell align="center">Preço Negociado</TableCell>
              <TableCell align="center">CPA</TableCell>
              <TableCell align="center">Valor Final</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.filter((row) => !(row.excluido !== undefined && row.excluido)).map((row, i) => {
              const max = row.valorFinalCorteva;
              const recomendacoes = [];

              if (row.historico) {
                recomendacoes.push("Histórico");
              }
              if (row.recomendacaoMarketing) {
                recomendacoes.push("Marketing");
              }
              if (row.guiaPosicionamento) {
                recomendacoes.push("Guia de Posicionamento");
              }
              if (row.geracaoDemanda) {
                recomendacoes.push("Geração Demanda");
              }
              if (row.novoProduto) {
                recomendacoes.push("Novo Produto");
              }
              return (
                <TableRow key={row.produto}>
                  <TableCell>
                    {row.produto}
                  </TableCell>
                  <TableCell align="right">
                    {currency === "BRL" ?
                      (row.ultimaNegociacaoBrl ?? 0).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }) :
                      (row.ultimaNegociacaoUsd ?? 0).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "USD",
                      })
                    }
                  </TableCell>
                  <TableCell align="center">
                    {recomendacoes.join(", ")}
                  </TableCell>
                  <TableCell align="right">
                    {(Number((row.salesInput ?? 0).toFixed(2))).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {(Number((row.cota ?? 0).toFixed(2))).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {
                      row.preco.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <TableCell align="right">
                    {Number(invertSignal(row.descontoCategoriaMax * 100).toFixed(2)).toLocaleString("pt-BR")}%
                  </TableCell>
                  <CellInputAlert
                    name="descontoCategoria"
                    placeholder="Desconto Categoria %"
                    id="outlined-size-small"
                    value={row.descontoCategoria}
                    onValueChange={(values, sourceInfo) => actions.actions.handleChange({ target: { name: sourceInfo.event.target.name, value: Number(values.value) } }, i, row.produto, currency)}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    customInput={TextField}
                    size="small"
                    suffix={"%"}
                    sx={{ input: { textAlign: "right" } }}
                    maxValue={row.descontoCategoriaMax * 100}
                    messageError={"Desconto acima do permitido!"}
                    allowNegative={false}
                  />

                  <TableCell align="right">
                    {Number(invertSignal(row.descontoComboMax).toFixed(2)).toLocaleString("pt-BR")}%
                  </TableCell>
                  <CellInputAlert
                    name="descontoCombo"
                    placeholder="Desconto Combo %"
                    id="outlined-size-small"
                    value={row.descontoCombo}
                    onValueChange={(values, sourceInfo) => actions.actions.handleChange({ target: { name: sourceInfo.event.target.name, value: Number(values.value) } }, i, row.produto, currency)}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    customInput={TextField}
                    size="small"
                    suffix={"%"}
                    sx={{ input: { textAlign: "right" } }}
                    maxValue={valorizacaoBarter ? 0 : row.descontoComboMax}
                    messageError={valorizacaoBarter ? "Não é possível utilizar o desconto combo com valorização barter." : "Desconto acima do permitido!"}
                    allowNegative={false}
                  />

                  <TableCell align="right">
                    {
                      row.valorFinalCorteva.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <CellInputAlert
                    name="precoProposto"
                    placeholder="Preço Proposto"
                    id="outlined-size-small"
                    value={row.precoProposto}
                    onValueChange={(values, sourceInfo) => actions.actions.handleChange({ target: { name: sourceInfo.event.target.name, value: Number(values.value) } }, i, row.produto, currency)}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    customInput={TextField}
                    size="small"
                    sx={{ input: { textAlign: "right" } }}
                    allowNegative={false}
                  />
                  <CellInputAlert
                    name="volume"
                    placeholder="Volume"
                    id="outlined-size-small"
                    value={row.volume}
                    onValueChange={(values, sourceInfo) => actions.actions.handleChange({ target: { name: sourceInfo.event.target.name, value: Number(values.value) } }, i, row.produto, currency)}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    customInput={TextField}
                    size="small"
                    sx={{ input: { textAlign: "right" } }}
                    maxValue={500000}
                    messageError={"Volume acima do permitido!"}
                    allowNegative={false}
                  />
                  <CellInputAlert
                    name="precoNegociado"
                    placeholder="Preço Negociado"
                    value={row.precoNegociado}
                    onValueChange={(values, sourceInfo) => actions.actions.handleChange({ target: { name: sourceInfo.event.target.name, value: Number(values.value) } }, i, row.produto, currency)}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    size="small"
                    sx={{ input: { textAlign: "right" } }}
                    maxValue={max}
                    messageError={"Preço negociado acima do valor final Corteva."}
                    allowNegative={false}
                    InputProps={{
                      startAdornment: <> {row.precoNegociado > max && <Tooltip title="Preço negociado acima do valor final Corteva."><ErrorIcon color="laranja" /></Tooltip>}</>
                    }}
                  />

                  <TableCell align="right">
                    {
                      row.cpa.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>

                  <TableCell align="right">
                    {
                      row.valorFinal.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <TableCell>
                    {/* {row.analytics ?
                      <ActionDialogButton ButtonNode={IconButton}
                        title="Deseja Realmente remover?"
                        message={<>Escreva o motivo da remoção do produto <b>{row.produto}</b> recomendado para o Cliente:</>}
                        defaultMotivo={row.motivo}
                        onClick={(motivo) => { actions.actions.removeRow(undefined, i, row.produto, motivo) }}
                        sendText="Remover"
                        validInput={true}
                      >
                        <DeleteIcon />
                      </ActionDialogButton>
                      : */}
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => { actions.actions.removeRow(e, i, row.produto); }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    {/* } */}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer >
  );
}