import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, MenuItem, Paper, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import AllowUseNavigate, { navigate } from '../../../../Navigate';
import { adminSimuladorVeActions } from '../../../../../actions/admin/simuladorVe.actions';
import AddIcon from '@mui/icons-material/Add';
import { adminTerritoryActions } from '../../../../../actions/admin/territory.actions';
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { isNullOrEmpty } from '../../../../../helpers';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        valueIsNumericString
      />
    );
  },
);

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
class AdminSimuladorCreateFornecedorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      fornecedor: "",
      grupoMaterial: "",
      areaComercial: "",
      distrito: "",
      territorio: "",
      produto: "",
      precoMinimo: "",
      precoSugerido: "",
      prazoEntrega: "",
      moeda: "",
      addedItems: [],
      produtos: [],
      open: false,
      currency: "",
      isLoading: false,
      page: 0,
      rowsPerPage: 10,
      selectedRow: undefined
    };

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchProdutos = this.fetchProdutos.bind(this);
    this.saveDisponibilidade = this.saveDisponibilidade.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({ id: params.DisponibilidadeId });

    this.props.actions.requestCampanhas();
    this.props.actions.requestFornecedores();
    this.props.actions.requestGrupoMateriais();
    this.props.actions.requestTerritorios();

    this.props.actions.loadDisponibilidade(params.DisponibilidadeId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adminSimuladorVe.disponibilidade !== this.props.adminSimuladorVe.disponibilidade) {
      const disponibilidade = this.props.adminSimuladorVe.disponibilidade;
      this.fetchProdutos(disponibilidade.fornecedorId, disponibilidade.grupoMaterialId);

      this.setState({
        fornecedor: disponibilidade.fornecedorId,
        grupoMaterial: disponibilidade.grupoMaterialId,
        campanha: disponibilidade.campanhaId
      });
    }
  }

  handleChangePage(_, newPage) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });

    if (name === 'campanha') {
      const fornecedor = this.props.adminSimuladorVe?.fornecedores?.[0]?.id;
      const grupoMaterial = this.props.adminSimuladorVe?.grupoMaterial?.[0]?.id;
      this.setState({ fornecedor: fornecedor, grupoMaterial: grupoMaterial });

      this.fetchProdutos(fornecedor, grupoMaterial);
      this.checkFornecedorGrupoMaterialExists(fornecedor, grupoMaterial, value);
    }
    else if (name === 'areaComercial') {
      this.setState({ distrito: '', territorio: '' });
    }
    else if (name === 'distrito') {
      this.setState({ territorio: '' });
    }
  }

  fetchProdutos(fornecedor, grupoMaterial) {
    if (fornecedor && grupoMaterial) {
      this.props.actions.requestProdutosByFornecedorGrupo(fornecedor, grupoMaterial)
        .then((produtos) => {
          this.setState({ produtos });
        });
    } else {
      this.setState({ produtos: [] });
    }
  }

  handleAddItem = () => {
    this.setState({ isLoading: true });
    const { areaComercial, distrito, territorio, produto, precoMinimo, precoSugerido, moeda, prazoEntrega } = this.state;
    const produtos = this.props.adminSimuladorVe.produtos ? this.props.adminSimuladorVe.produtos : [];
    const rows = [...this.props.adminSimuladorVe.rows];

    if (!(rows.find((x) => this.state.produto.id === x.produto.id && this.state.moeda === x.moeda))) {
      this.props.actions.addRow(
        produto,
        produtos,
        areaComercial,
        distrito,
        territorio,
        precoMinimo,
        precoSugerido,
        moeda,
        prazoEntrega
      );
    }

    this.setState({ isLoading: false });
  };

  getPaginatedRows() {
    const { rows } = this.props.adminSimuladorVe;
    const { page, rowsPerPage } = this.state;

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return rows.slice(startIndex, endIndex);
  }

  handleClickOpen = (row) => {
    this.setState({ open: true, selectedRow: row });
  };

  handleTogglePrice = (row) => {
    this.props.actions.toggleRow(row);
  };

  handleChangeValue = (id, name, value) => {
    this.props.actions.changeRowValue(id, name, value);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDeleteRow = () => {
    this.props.actions.deleteRow(this.state.selectedRow);
    this.handleClose();
  };

  saveDisponibilidade = (event) => {
    event.preventDefault();
    const { rows } = this.props.adminSimuladorVe;
    this.props.actions.updateDisponibilidade(
      this.state.id,
      this.state.campanha,
      this.state.fornecedor,
      this.state.grupoMaterial,
      rows,
      navigate
    ).then(() => {
      this.props.actions.removeAllRows();
    });
  };

  render() {
    const { rows } = this.props.adminSimuladorVe;
    const paginatedRows = this.getPaginatedRows();
    const isLoading = this.props.adminSimuladorVe.isLoadingFornecedor;

    const campanhas = this.props.adminSimuladorVe?.campanhas ? this.props.adminSimuladorVe.campanhas : [];
    const territorios = this.props.adminTerritory?.territorios ? this.props.adminTerritory.territorios : [];
    const produtos = this.props.adminSimuladorVe?.produtos ? this.props.adminSimuladorVe.produtos : [];

    const areaComercialSet = new Set(territorios
      .filter(x => x.areaName !== "")
      .map(x => x.areaName)
    );
    const distritoSet = new Set(
      territorios
        .filter(x => x.areaName === this.state.areaComercial && x.districtName !== "")
        .map(x => x.districtName)
    );
    const territorioSet = new Set(
      territorios
        .filter(x => x.areaName === this.state.areaComercial && x.districtName === this.state.distrito && x.territoryName !== "")
        .map(x => x.territoryName)
    );

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading || this.state.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Admin-Precos"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Precos"}>
                Preços
              </Link>
              <Typography color="text.primary">Editar Preços</Typography>
            </Breadcrumbs>
          </Stack>
        </div>

        <Dialog
          open={this.state.open}
          onClose={() => this.handleClose()}
          aria-labelledby="exclude-price-item-label"
          aria-describedby="exclude-price-item-description"
        >
          <DialogTitle id="exclude-price-item-title">
            Deseja realmente exluir este item?
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => this.handleClose()}>Cancelar</Button>
            <Button variant="contained" onClick={() => this.handleDeleteRow()} autoFocus>
              Exluir
            </Button>
          </DialogActions>
        </Dialog>

        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <form
            onSubmit={this.saveDisponibilidade}
            autoComplete="off"
          >
            <Grid direction="row" container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <label className="d-block">Campanha</label>
                <Select
                  name="campanha"
                  label="Campanha"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={this.state.campanha || ""}
                  onChange={this.handleChange}
                >
                  {
                    campanhas.map((x, index) => (
                      <MenuItem key={index} value={x.id}>{x.nome}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <label className="d-block">Área Comercial</label>
                <Select
                  name='areaComercial'
                  value={this.state.areaComercial || ""}
                  placeholder="Área Comercial"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {Array.from(areaComercialSet).map((x) => (
                    <MenuItem value={x}>{x}</MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <label>Distrito</label>
                <Select
                  name='distrito'
                  value={this.state.distrito || ""}
                  placeholder="Distrito"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {Array.from(distritoSet).map((x) => (
                    <MenuItem value={x}>{x}</MenuItem>
                  ))}
                  <MenuItem key={"removeDistrict"} value={null}>Nenhum</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <label>Território</label>
                <Select
                  name='territorio'
                  value={this.state.territorio || ""}
                  placeholder="Território"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {Array.from(territorioSet).map((x) => (
                    <MenuItem key={x} value={x}>{x}</MenuItem>
                  ))}
                  <MenuItem key={"removeTerritory"} value={null}>Nenhum</MenuItem>
                </Select>
              </Grid>

              <Grid item md={12} sx={{ fontWeight: "bolder" }}>
                Selecione os parâmetros abaixo:
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <label>Produto</label>
                <Select
                  name='produto'
                  value={this.state.produto || ""}
                  placeholder="Produto"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {produtos.map((x, index) => (
                    <MenuItem key={index} value={x.id}>{x.nome} - {x.sku}</MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <label>Moeda</label>
                <Select
                  name='moeda'
                  value={this.state.moeda || ""}
                  placeholder="Moeda"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  <MenuItem value="BRL">BRL</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <label>Preço Mínimo</label>
                <TextField
                  name='precoMinimo'
                  value={this.state.precoMinimo || ""}
                  onChange={this.handleChange}
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </Grid>

              <Grid item xs={10} sm={10} md={2}>
                <label>Preço Sugerido</label>
                <TextField
                  name='precoSugerido'
                  value={this.state.precoSugerido || ""}
                  onChange={this.handleChange}
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </Grid>

              <Grid item xs={10} sm={10} md={2.5}>
                <label>Prazo de Entrega (Dias)</label>
                <TextField
                  name='prazoEntrega'
                  value={this.state.prazoEntrega || ""}
                  onChange={this.handleChange}
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </Grid>

              <Grid item xs={2} sm={2} md={0.5} sx={{ alignContent: "end" }}>
                <IconButton
                  color="primary"
                  size="large"
                  aria-label="Adicionar"
                  fullWidth
                  onClick={this.handleAddItem}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            {!isNullOrEmpty(rows) && (
              <TableContainer component={Paper} sx={{ marginTop: 4 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Área Comercial</TableCell>
                      <TableCell>Distrito</TableCell>
                      <TableCell>Território</TableCell>
                      <TableCell>Produto</TableCell>
                      <TableCell>Moeda</TableCell>
                      <TableCell>Preço Mínimo</TableCell>
                      <TableCell>Preço Sugerido</TableCell>
                      <TableCell>Prazo de Entrega (Dias)</TableCell>
                      <TableCell>Data de Disponibilidade</TableCell>
                      <TableCell align="center" colSpan={2}>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedRows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.areaComercial}</TableCell>
                        <TableCell>{row.distrito}</TableCell>
                        <TableCell>{row.territorio}</TableCell>
                        <TableCell>{row.produto.nome} - {row.produto.sku}</TableCell>
                        <TableCell>{row.moeda}</TableCell>
                        <TableCell>
                          {Number(row.precoMinimo).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (row.moeda === "BRL" ? "BRL" : "USD"),
                          })}
                        </TableCell>
                        <TableCell>
                          {Number(row.precoSugerido).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (row.moeda === "BRL" ? "BRL" : "USD"),
                          })}
                        </TableCell>
                        <TableCell>
                          {Number(row.prazoEntrega)}
                        </TableCell>
                        <TableCell>
                          <DatePicker
                            fullWidth
                            format="DD/MM/YYYY"
                            openTo="year"
                            color="azul"
                            id={"dataDisponibilidade" + row.id}
                            value={dayjs(row.dataDisponibilidade)}
                            onChange={(newValue) => this.handleChangeValue(row.id, "dataDisponibilidade", newValue ? dayjs(newValue) : null)}
                            views={['year', 'month', 'day']}
                            sx={{ width: "100%", '& div fieldset': { border: "none" } }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={row.status ? "Inativar" : "Ativar"}>
                            <Switch
                              key={row.id + "switch" + index}
                              checked={row.status}
                              onChange={() => this.handleTogglePrice(row)}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={"Deletar"}>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => this.handleClickOpen(row)}
                              sx={{ color: "#000" }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage="Linhas por página:"
                  component="div"
                  count={rows.length}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  rowsPerPage={this.state.rowsPerPage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage} />
              </TableContainer>
            )}
            {rows.length > 0 && (
              <div>
                <Button sx={{ marginTop: "10px" }} variant="contained" color="primary" fullWidth type="submit">
                  Salvar
                </Button>

              </div>
            )}

          </form>

        </div>
      </div >
    );
  }

}
export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminTerritoryActions,
        ...adminSimuladorVeActions,
      }, dispatch),
    };
  }
)(AdminSimuladorCreateFornecedorPage);
