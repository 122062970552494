const cnpj = (cnpj_value) => {
  const weights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const digits = String(cnpj_value).replace(/[^\d]/g, "");

  if (digits.length !== 14) return false;

  if (/0{14}/.test(digits)) return false;

  let n = 0;
  for (let i = 0; i < 12; n += digits[i] * weights[++i]);
  if (Number(digits[12]) !== ((n %= 11) < 2 ? 0 : 11 - n)) return false;

  n = 0;
  for (let i = 0; i <= 12; n += digits[i] * weights[i++]);
  if (Number(digits[13]) !== ((n %= 11) < 2 ? 0 : 11 - n)) return false;

  return true;
};

export const validation = { cnpj };

export const GetSafeNumber = (value = 0, toFixed = undefined) => value == null || value === undefined || Number.isNaN(Number(value)) || !Number.isFinite(Number(value)) ? 0 : (toFixed !== undefined ? Number(value.toFixed(toFixed)) : Number(value));

export function isNullOrEmpty(value) {
  return value === null || 
         value === undefined || 
         (typeof value === 'string' && value?.length === 0) || 
         (Array.isArray(value) && value?.length === 0);
}

export function isDefaultDate(str) {
  const date = new Date(str);
  return date.valueOf() === -62135585612000 || Number.isNaN(date.valueOf());
}

function isErrorWithMessage(error) {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error).message === 'string'
  )
}

export function toErrorWithMessage(maybeError) {
  if (isErrorWithMessage(maybeError)) return maybeError

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError))
  }
}

export async function HandleResponse(response, isJson = true) {
  if (!response.ok) {
    try {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        window.location.href = "/login"
      }

      if (response.status === 403) {
        window.location.href = "/"
      }

      let text = response.statusText;

      try {
        text = await response.json();
      } catch (errorTextParse) {
        if (response.status === 500) {
          text = "Houve um erro interno na aplicação!";
        } else if (response.status === 403) {
          text = "Você não possui permissão para completar a operação!";
        } else {
          text = "Não foi possível completar a operação!";
        }
      }

      return Promise.reject(text);
    }
    catch (error) {
      const errorMessage = (toErrorWithMessage(error).message) || response.statusText;
      return Promise.reject(errorMessage);
    }
  }

  try {
    const text = await response.text();

    if (isNullOrEmpty(text)) {
      return Promise.resolve();
    }

    if (!isJson) {
      return text;
    }

    return JSON.parse(text);
  } catch (error2) {
    return Promise.reject("Não foi possível converter o retorno obtido da API");
  }
}