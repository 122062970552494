/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { userActions } from "../../../actions/user.actions";
import { clientTerritoryActions } from "../../../actions/clientTerritory.actions";
import { precoVeActions } from "../../../actions/precove.actions";
import { simuladorVeEncargosActions } from "../../../actions/simuladorVeEncargos.action";
import { produtoveActions } from "../../../actions/produtove.action";
import { campaignVeActions } from "../../../actions/campaignVe.actions";
import { Alert, Checkbox, FormControlLabel, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, createTheme, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { NumericFormat } from "react-number-format";
import { simuladorVeActions } from "../../../actions/simuladorVendaExterna.actions";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import ConfirmationDialog from "./components/SelectNewProduto";
import { isNullOrEmpty } from "../../../helpers";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          minWidth: "90px",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
            minWidth: "fit-content"
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    // maxWidth: 220,
    border: 'none',
  },
}));

const CellInputAlert = ({ messageError, maxValue, minValue, needMultiple, multipleOf, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const isAllowed = (value, max, min, needMultiple, multipleOf) => {
    const number = Number(value.value);

    if (needMultiple && number % multipleOf !== 0) {
      setOpen(true);
    }
    else {
      setOpen(false);
    }

    if (maxValue === undefined || maxValue === null || Number.isNaN(maxValue) || maxValue < 0) {
      return true;
    }

    if (number > max || number < min) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return number <= max;
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <HtmlTooltip placement="right" leaveDelay={600} open={open} onClose={handleClose}
      title={
        <React.Fragment>
          <Alert severity="error">
            {messageError}
          </Alert>
        </React.Fragment>
      }
    >
      <TableCell align="center">
        <NumericFormat
          {...props}
          isAllowed={(values) => isAllowed(values, maxValue, minValue, needMultiple, multipleOf)}
        />
      </TableCell>
    </HtmlTooltip>
  );
}

class SimulatorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cenarioId: null,
      produto: "",
      encargos: "",

      campanha: "",
      campanhaAux: "",

      moeda: "",
      moedaAuxiliar: "",

      prazoPagamento: "",
      prazoAuxiliar: "",

      currency: "",
      volume: "",

      barter: false,
      observacao: "",

      open: false,
      status: "",
      canSave: false,
      precoNegociado: "",

      mensagem: "",
      botao: "",

      selectedRow: "",
      openProducts: false,

      minCampaignDeliveryDate: undefined,
      maxCampaignDeliveryDate: undefined
    };
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.setState({ cli: params.GroupId });

    this.props.actions.requestClientTerritories();
    this.props.actions.requestEncargos();
    this.props.actions.requestCampanhas();
    this.props.actions.simulacaoCliente(params.GroupId);

    if (!isNullOrEmpty(params.CenarioId)) {
      this.setState({ cenarioId: params.CenarioId });

      this.props.actions.loadCenario(params.CenarioId)
        .then(() => {
          const cenario = this.props.simuladorVendaExterna.cenario;
          if (cenario) {
            const campaign = this.props.campaignVe.campaigns.find(x => x.id == cenario.campanhaId);
            const minCampaignDeliveryDate = dayjs(campaign?.dataInicio);
            const maxCampaignDeliveryDate = dayjs(campaign?.dataFim);

            this.setState({
              campanha: cenario.campanhaId,
              moeda: cenario.moeda ?? "",
              prazoPagamento: cenario.condicaoComercial ?? "",
              barter: cenario.barter ?? false,
              observacao: cenario.observacao ?? "",
              minCampaignDeliveryDate,
              maxCampaignDeliveryDate
            });

            this.props.actions.requestCondicaoComercial(cenario.moeda, cenario.campanhaId)
              .then(() => this.props.actions.requestProdutoPorTerritorio(this.state.cli, cenario.campanhaId, cenario.moeda));
          }
        });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearSimuladorVeData();
  }

  handleCampanha = (event) => {
    const { value } = event.target;
    this.props.actions.clearProdutos();

    if (this.props.simuladorVendaExterna.rows.length > 0) {
      this.setState({ campanhaAux: value });
      this.handleClickOpen(4, "");
    }

    const campagin = this.props.campaignVe.campaigns.find(x => x.id === value);
    const minCampaingDeliveryDate = dayjs(campagin?.dataInicio);
    const maxCampaingDeliveryDate = dayjs(campagin?.dataFim);
    this.setState({ campanha: value, minCampaingDeliveryDate, maxCampaingDeliveryDate, moeda: "", prazoPagamento: "" });
  };

  handleMoeda = (event) => {
    const { value } = event.target;
    this.props.actions.clearProdutos();

    this.setState({ produto: "" });
    if (this.state.moeda === "" || this.props.simuladorVendaExterna.rows.length === 0) {
      this.setState({ moeda: value });
      this.props.actions.requestCondicaoComercial(value, this.state.campanha);

      if (this.state.prazoPagamento)
        this.props.actions.requestProdutoPorTerritorio(this.state.cli, this.state.campanha, value);
    }

    if (this.props.simuladorVendaExterna.rows.length > 0) {
      this.setState({ moedaAuxiliar: value });
      this.handleClickOpen(2, "");
    }
  };

  handleCondicaoComercial = (event) => {
    this.setState({ produto: "" });
    if (this.state.moeda === "" || this.props.simuladorVendaExterna.rows.length === 0) {
      this.setState({ prazoPagamento: event.target.value });
      this.props.actions.requestProdutoPorTerritorio(this.state.cli, this.state.campanha, this.state.moeda);
    }

    if (this.props.simuladorVendaExterna.rows.length > 0) {
      this.setState({ prazoAuxiliar: event.target.value });
      this.handleClickOpen(3, "");
    }
  };

  handleChangeVolume = (event, index) => {
    const volume = event.floatValue;
    const rows = [...this.props.simuladorVendaExterna.rows];

    rows[index].volume = volume;
    if (volume > 0) {
      rows[index].valorTotal = rows[index].precoSugerido * volume;
      rows[index].valorFinalNegociado = rows[index].precoNegociado * volume;
    } else {
      rows[index].valorTotal = rows[index].precoSugerido;
      rows[index].valorFinalNegociado = rows[index].precoNegociado;
    }

    this.props.actions.updateRows(rows);
    this.setState({ volume });
  };

  handleChangePrecoNegociado = (event, index) => {
    var precoNegociado = event.floatValue;
    const rows = [...this.props.simuladorVendaExterna.rows];
    if (precoNegociado === undefined) {
      precoNegociado = 0;
    }

    rows[index].precoNegociado = precoNegociado;
    if (rows[index].volume > 0) {
      rows[index].valorFinalNegociado = rows[index].precoNegociado * rows[index].volume;
    } else {
      rows[index].valorFinalNegociado = precoNegociado;
    }

    this.props.actions.updateRows(rows);
    this.setState({ precoNegociado });
  };

  handleChangeDate = (e, index) => {
    const newDate = new Date(e);
    const rows = [...this.props.simuladorVendaExterna.rows];
    rows[index].dataEntrega = newDate;

    this.props.actions.updateRows(rows);
  };

  handleClickOpen = (opcao, produto) => {
    switch (opcao) {
      case 1:
        this.setState({
          botao: "Excluir",
          mensagem: "Deseja realmente exluir este produto?"
        });
        break;
      case 2:
        this.setState({
          botao: "Alterar",
          mensagem: "Ao realizar a troca de moeda, todos os dados simulados serão exluídos. Deseja realmente alterar a mesma?"
        });
        break;
      case 3:
        this.setState({
          botao: "Alterar",
          mensagem: "Ao realizar a troca de Prazo de Pagamento, todos os dados simulados serão exluídos. Deseja realmente alterar o mesmo?"
        });
        break;
      case 4:
        this.setState({
          botao: "Alterar",
          mensagem: "Ao realizar a troca da Campanha, todos os dados simulados serão exluídos. Deseja realmente alterar a mesma?"
        });
        break;
      default:
        break;
    }

    this.setState({ open: true, selectedRow: produto });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.setState({ moedaAuxiliar: "" });
    this.setState({ prazoAuxiliar: "" });
    this.setState({ campanhaAux: "" });
  };

  handleDeleteRow = () => {
    if (this.state.botao === "Alterar") {
      this.props.actions.removeAllRows();
      if (this.state.moedaAuxiliar !== "") {
        this.setState({ prazoPagamento: "" });
        this.setState({ moeda: this.state.moedaAuxiliar });
        this.props.actions.requestCondicaoComercial(this.state.moedaAuxiliar, this.state.campanha);
      }
      if (this.state.prazoAuxiliar !== "") {
        this.setState({ prazoPagamento: this.state.prazoAuxiliar });
        this.props.actions.requestProdutoPorTerritorio(this.state.cli, this.state.campanha, this.state.moeda);
      }
      if (this.state.campanhaAux !== "") {
        this.setState({ campanha: this.state.campanhaAux });
      }
    }
    if (this.state.botao === "Excluir") {
      this.props.actions.deleteRow(this.state.selectedRow);

    }
    this.handleClose();
  }

  addProduct = (territorio) => (value) => {
    this.props.actions.requestPrecoProduto(value, this.state.campanha, territorio.territoryId, this.state.moeda)
      .then(() => {
        const produto = this.props.produtosVendaExterna.produtos.find((produto) => produto.id == value);
        const rows = this.props.simuladorVendaExterna.rows ?? [];

        if (rows.some(item => item.produtoId == produto.id)) {
          this.setState({ open: true, botao: "Ok", mensagem: "O produto já está no carrinho." })
          return;
        }

        const maxOrdem = rows.length > 0 ? Math.max(...rows.map(x => x.ordem || 0)) : 0;

        this.props.actions.addRow(
          maxOrdem + 1,
          produto,
          this.props.precoVendaExterna.precos,
          this.props.encargoVendaExterna.encargos,
          this.state.moeda,
          this.state.prazoPagamento,
        );

        this.setState({ canSave: true });
      })
  }

  saveCenario = (total, status) => {
    const rows = this.props.simuladorVendaExterna.rows.map((row) => {
      const dataComPrazoEntrega = this.getMinDate(row.prazoEntrega);
      const dataDisponibilidade = row.dataDisponibilidade ? dayjs(row.dataDisponibilidade) : undefined;
      let minDate;

      if (dataDisponibilidade && dataDisponibilidade > dataComPrazoEntrega) {
        if (dataDisponibilidade > this.state.minCampaingDeliveryDate) {
          minDate = this.getMinDate(row.prazoEntrega, dataDisponibilidade);
        } else {
          minDate = this.state.minCampaingDeliveryDate < dataComPrazoEntrega
            ? this.getMinDate(row.prazoEntrega, this.state.minCampaingDeliveryDate)
            : dataComPrazoEntrega;
        }
      } else {
        minDate = dataComPrazoEntrega > this.state.minCampaingDeliveryDate
          ? dataComPrazoEntrega
          : this.getMinDate(row.prazoEntrega, this.state.minCampaingDeliveryDate);
      }

      const defaultValue = row.dataEntrega < minDate
        ? minDate
        : row.dataEntrega;

      return {
        ...row,
        dataEntrega: defaultValue,
      }
    });

    if (this.state.cenarioId) {
      this.props.actions.updateCenario(
        this.state.cenarioId,
        status,
        total,
        this.state.campanha,
        this.state.moeda,
        this.state.prazoPagamento,
        rows,
        this.state.barter,
        this.state.observacao
      ).then(() => {
        this.props.actions.removeAllRows();
        this.props.actions.simulacaoCliente(this.state.cli);
      })
    }
    else {
      this.props.actions.createSaveGenerateCenario(
        this.state.cli,
        status,
        rows,
        total,
        this.state.campanha,
        this.state.moeda,
        this.state.prazoPagamento,
        this.state.barter,
        this.state.observacao
      ).then(() => {
        this.props.actions.removeAllRows();
        this.props.actions.simulacaoCliente(this.state.cli);
      });
    }
  }

  getMinDate = (prazo, date = undefined) => {
    if (date === undefined)
      return dayjs().add(prazo, 'day');
    else
      return dayjs(date).add(prazo, 'day');
  };

  render() {
    const { cli } = this.state;
    const selectedClient = this.props.clientesVendaExterna.clients.find(client => client.id == cli);
    
    const account = selectedClient?.account;
    const territorio = selectedClient?.territory;

    const rows = this.props.simuladorVendaExterna.rows ? this.props.simuladorVendaExterna.rows : [];
    const total = rows.reduce((acc, row) => acc + row.valorFinalNegociado, 0);

    let canSave = false;
    let canFinalizar = false;

    if (this.props.simuladorVendaExterna.rows.length === 0) {
      canSave = false;
    } else {
      canSave = true;

      const hasError = !isNullOrEmpty(rows.find((x) => x.volume === 0 || x.volume === undefined ||
        x.precoNegociado === 0 || x.precoNegociado === undefined ||
        x.precoNegociado < x.precoMinimo || x.dataEntrega === undefined || x.dataEntrega > this.state.maxCampaingDeliveryDate ||
        x.volume % x.produto.multiploVolume !== 0));

      const totalVolume = rows.reduce((acc, row) => acc + row.volume, 0);
      const hasntMinValue = this.state.moeda === "BRL" ? total < 40000 : total < 7000;
      const hasntMinVolume = totalVolume < 1000;

      if (hasError || hasntMinValue || hasntMinVolume) {
        canFinalizar = false;
      } else {
        canFinalizar = true;
      }
    }

    return (
      <div className="card">
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/SimuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/simuladorVe"}
              >
                C360 Light
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/simuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}
              >
                Meu Pedidos
              </Link>
              <Typography color="text.primary">Novo Pedido</Typography>
            </Breadcrumbs>
          </Stack>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.mensagem}
          </DialogTitle>
          <DialogActions>
            {this.state.botao === "Ok" ?
              <Button variant="contained" onClick={this.handleClose} autoFocus>
                {this.state.botao}
              </Button> :
              <>
                <Button onClick={this.handleClose}>Cancelar</Button>
                <Button variant="contained" onClick={this.handleDeleteRow} autoFocus>
                  {this.state.botao}
                </Button>
              </>
            }
          </DialogActions>
        </Dialog>

        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ color: "var(--cort-blue)" }}>{account?.nome ?? ""}</h1>
              </div>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Account:</span> {account?.accountId}</h6>
              {/* <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Território:</span> {territorio?.territoryName}</h6> */}
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Distrito:</span> {territorio?.districtName}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Endereço:</span> {account?.endereco}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Cidade:</span> {account?.municipio} - {account?.uf}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Inscrição Estadual:</span> {account?.ie}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>CPF/CNPJ:</span> {account?.cpfCnpj}</h6>
            </Grid>

            <Grid item xs={2} sm={8} md={6}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                {total > 0 && (
                  <>
                    <Grid item xs={2} sm={8} md={8} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        <b>Valor total do Pedido: </b>
                      </h4>
                    </Grid>
                    <Grid item xs={2} sm={8} md={4} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        {
                          total.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                          })
                        }
                      </h4>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={12} sx={{ alignSelf: "flex-end" }}>
              <Stack direction={{ xs: "column", sm: "column", md: "row" }} gap={2} justifyContent={"center"}>
                {canSave ?
                  <Link to={`/SimuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}>
                    <Button
                      onClick={() => this.saveCenario(total, "Rascunho")}
                      variant="contained"
                      color="laranja"
                      sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                    >
                      Salvar Rascunho
                    </Button>
                  </Link> :
                  <Tooltip title="Não é possivel salvar o Pedido sem condição de pagamento e vencimento">
                    <span>
                      <Button
                        disabled={true}
                        variant="contained"
                        color="laranja"
                        sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                      >
                        Salvar Rascunho
                      </Button>
                    </span>
                  </Tooltip>
                }
                {canFinalizar ? <Link
                  to={`/SimuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}
                >
                  <Button
                    onClick={() => this.saveCenario(total, "Finalizado")}
                    variant="contained"
                    color="verde"
                    sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                  >
                    Finalizar Pedido
                  </Button>
                </Link> :
                  <Tooltip title="Preencha todos os campos para finalizar o pedido">
                    <span>
                      <Button
                        disabled={true}
                        variant="contained"
                        color="verde"
                        sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                      >
                        Finalizar Pedido
                      </Button>
                    </span>
                  </Tooltip>
                }
                <Link
                  to={`/SimuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}
                >
                  <Button
                    variant="contained"
                    color="vermelho"
                    onClick={() => this.deleteRows()}
                    sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                  >
                    Sair sem Salvar
                  </Button>
                </Link>
              </Stack>
            </Grid>
            <Grid item xs={2} sm={8} md={12} >
              <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                  <p>Selecione os parâmetros do Pedido:</p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={4}>
              <label className="d-block">Campanha</label>
              <Select
                value={this.state.campanha}
                placeholder="Campanha"
                onChange={this.handleCampanha}
                sx={{ minWidth: 130, width: "100%" }}
                size="small"
                disabled={this.state.cli === 0}
              >
                {this.props.campaignVe.campaigns && this.props.campaignVe.campaigns
                  .sort((a, b) => a.nome - b.nome)
                  .map(x => {
                    return (
                      <MenuItem key={x.id} value={x.id}>{x.nome}</MenuItem>
                    )
                  })}
              </Select>
            </Grid>

            <Grid item xs={2} sm={8} md={1.5}>
              <label className="d-block">Moeda</label>
              <Select
                value={this.state.moeda}
                placeholder="Moeda"
                onChange={this.handleMoeda}
                sx={{ minWidth: 130, width: "100%" }}
                size="small"
              >
                <MenuItem value={"BRL"}>BRL</MenuItem>
                <MenuItem value={"USD"}>USD</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={2} sm={8} md={4}>
              <label>Prazo de Pagamento</label>
              <Select
                value={this.state.prazoPagamento}
                placeholder="Prazo de Pagamento"
                onChange={this.handleCondicaoComercial}
                sx={{ width: "100%" }}
                size="small"
              >
                {!this.props.encargoVendaExterna.isLoading &&
                  this.props.encargoVendaExterna.condicaoComercial
                    .sort((a, b) => new Date(a.condicaoComercial) - new Date(b.condicaoComercial))
                    .map((prazoPagamento) => (
                      <MenuItem
                        key={prazoPagamento.id}
                        value={prazoPagamento.condicaoComercial}
                      >
                        {prazoPagamento.condicaoComercial}
                      </MenuItem>
                    ))}
              </Select>
            </Grid>

            <Grid item xs={2} sm={8} md={2.5}>
              <ConfirmationDialog
                variant="outlined"
                color={"azul"}
                endIcon={<AddShoppingCartIcon />}
                fullWidth
                sx={{ marginTop: "2rem", height: "40px" }}
                options={this.props.produtosVendaExterna.produtos.sort((a, b) => a.nome.localeCompare(b.nome)).map((produto) => { return { label: produto.nome + " - " + produto.sku, value: produto.id } })}
                onChange={this.addProduct(territorio)}
              >
                Adicionar Produto
              </ConfirmationDialog>
            </Grid>

            <Grid item xs={2} sm={8} md={12} >
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }} className="rounded-3">
                <Grid item xs={2} sm={8} md={10.5}>
                  <label>Observação</label>
                  <TextField
                    color={'azul'}
                    fullWidth
                    size="small"
                    id="observacao"
                    name="observacao"
                    variant="outlined"
                    value={this.state.observacao}
                    onChange={(e) => this.setState({ observacao: e.target.value })}
                  />
                </Grid>

                <Grid item xs={2} sm={8} md={1.5}>
                  <div style={{ marginBottom: ".5rem", opacity: "0" }}>SPACING</div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        value={this.state.barter}
                        onChange={(e) => this.setState({ barter: e.target.checked })}
                      />
                    }
                    id="barter"
                    name="barter"
                    label="É Barter"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>

            {this.props.simuladorVendaExterna.length > 0 && (
              <Grid item xs={2} sm={8} md={12} >
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "0 !important", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                  <Grid item xs={2} sm={1.5} md={2.5} sx={{ textAlign: { xs: "center", sm: "left" }, paddingTop: "1rem !important" }}>
                    <p>Cesta do Cliente</p>
                  </Grid>
                  <Grid item xs={2} sm={5.5} md={8} sx={{ textAlign: "center", paddingTop: "1rem !important" }}>
                    <p>(Informe os dados de Volume)</p>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={2} sm={8} md={12}>
              {this.props.simuladorVendaExterna.rows.length === 0 && (
                <p>Use a caixa de seleção acima para iniciar um pedido</p>
              )}
              {this.props.simuladorVendaExterna.rows.length > 0 &&
                <TableContainer component={Paper}>
                  <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="custom pagination table"
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            th: {
                              fontWeight: "bold",
                              color: "white",
                            },
                            backgroundColor: "var(--cort-blue)"
                          }}
                        >
                          <TableCell align="center">Ordem</TableCell>
                          <TableCell align="center">Produto</TableCell>
                          <TableCell align="center">Preço Sugerido</TableCell>
                          <TableCell align="center">Preço Mínimo</TableCell>
                          <TableCell align="center">Múltiplo</TableCell>
                          <TableCell align="center">Quantidade (Kg / L)</TableCell>
                          <TableCell align="center">Preço Negociado</TableCell>
                          <TableCell align="center">Data de Entrega</TableCell>
                          <TableCell align="center">Valor Final</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => {
                          const dataComPrazoEntrega = this.getMinDate(row.prazoEntrega);
                          const dataDisponibilidade = row.dataDisponibilidade ? dayjs(row.dataDisponibilidade) : undefined;
                          let minDate;

                          if (dataDisponibilidade && dataDisponibilidade > dataComPrazoEntrega) {
                            if (dataDisponibilidade > this.state.minCampaingDeliveryDate) {
                              minDate = this.getMinDate(row.prazoEntrega, dataDisponibilidade);
                            } else {
                              minDate = this.state.minCampaingDeliveryDate < dataComPrazoEntrega
                                ? this.getMinDate(row.prazoEntrega, this.state.minCampaingDeliveryDate)
                                : dataComPrazoEntrega;
                            }
                          } else {
                            minDate = dataComPrazoEntrega > this.state.minCampaingDeliveryDate
                              ? dataComPrazoEntrega
                              : this.getMinDate(row.prazoEntrega, this.state.minCampaingDeliveryDate);
                          }

                          const defaultValue = row.dataEntrega < minDate
                            ? minDate
                            : row.dataEntrega;

                          return (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row" align="center">
                                {row.ordem}
                              </TableCell>

                              <TableCell component="th" scope="row" align="center">
                                {row.produto.nome}
                              </TableCell>

                              <TableCell align="center">
                                {row.precoSugerido.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                                })}
                              </TableCell>

                              <TableCell align="center">
                                {row.precoMinimo.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                                })}
                              </TableCell>

                              <TableCell align="center">
                                {row.produto.multiploVolume}
                              </TableCell>

                              <CellInputAlert
                                name="volume"
                                placeholder="Quantidade (Kg / L)"
                                id="outlined-size-small"
                                value={row.volume}
                                onValueChange={(event) => this.handleChangeVolume(event, index)}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={0}
                                customInput={TextField}
                                size="small"
                                sx={{ input: { textAlign: "right" } }}
                                allowNegative={false}
                                needMultiple={true}
                                multipleOf={row.produto.multiploVolume}
                                messageError={"O valor deve ser múltiplo"}
                              />

                              <CellInputAlert
                                name="PrecoNegociado"
                                placeholder="Preço Negociado"
                                id="outlined-size-small"
                                value={row.precoNegociado}
                                onValueChange={(event) => this.handleChangePrecoNegociado(event, index)}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                customInput={TextField}
                                size="small"
                                sx={{ input: { textAlign: "right" } }}
                                maxValue={row.precoSugerido * 2}
                                minValue={row.precoMinimo}
                                messageError={"Preço informado abaixo do preço mínimo, por favor garantir que você possui aprovação da Somax."}
                                allowNegative={false}
                              />

                              <TableCell align="center">
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                  <DatePicker
                                    minDate={minDate}
                                    maxDate={this.state.maxCampaingDeliveryDate}
                                    value={defaultValue}
                                    onChange={(e) => this.handleChangeDate(e, index)}
                                    sx={{ 'div input': { padding: "8.5px 14px" } }}
                                  />
                                </LocalizationProvider>
                              </TableCell>

                              <TableCell align="center">
                                {row.valorFinalNegociado.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                                })}
                              </TableCell>

                              <TableCell align="center">
                                <IconButton aria-label="delete" size="small" onClick={() => this.handleClickOpen(1, row.produtoId)}>
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </ThemeProvider>
                </TableContainer >
              }
            </Grid>
          </Grid>


        </div>
      </div>
    );
  }

}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {

          ...clientTerritoryActions,
          ...precoVeActions,
          ...produtoveActions,
          ...campaignVeActions,
          ...simuladorVeEncargosActions,
          ...simuladorVeActions,
          ...userActions
        },
        dispatch
      ),
    };
  }
)(SimulatorPage);
