/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */

import { GetSafeNumber } from "../helpers";

const NEW_TEMPORALY_SECTION = "CREATE_TEMPORALY_SECTION";
const TOGGLE_SECTION = "TOGGLE_SECTION";
const CREATE_SECTION = "CREATE_SECTION";
const DELETE_SECTION = "DELETE_SECTION";

const CHANGE_SEED_CAMPAIGN = "CHANGE_SEED_CAMPAIGN";
const CHANGE_SEED_PAYMENT_TERM = "CHANGE_SEED_PAYMENT_TERM";
const CHANGE_SEED_PAYMENT_DATE = "CHANGE_SEED_PAYMENT_DATE";
const CHANGE_SEED_PRODUCT = "CHANGE_SEED_PRODUCT";
const CHANGE_SEED_BARTER = "CHANGE_SEED_BARTER";
const CHANGE_SEED_ADIMPLENCIA = "CHANGE_SEED_ADIMPLENCIA";

const CHANGE_SEED_VALUE = "CHANGE_SEED_VALUE";
const CHANGE_SEED_SUFFIX = "CHANGE_SEED_SUFFIX";

const CREATE_SEED_SIMULATION = "CREATE_SEED_SIMULATION";
const ADD_SEED_ROW = "ADD_SEED_ROW";
const REMOVE_SEED_ROW = "REMOVE_SEED_ROW";
const UPDATE_SEED_ROW = "UPDATE_SEED_ROW";
const REMOVE_SEED_ALL_ROW = "REMOVE_SEED_ALL_ROW";

const RECEIVE_SEED_CENARIO_SECTIONS = "RECEIVE_CENARIO_SECTIONS";

const CHANGE_LOAD_STATE = "CHANGE_LOAD_STATE";
const CLEAR_SEEDS_DATA = "CLEAR_SEEDS_DATA";

export const simuladorSeedsConstants = {
  NEW_TEMPORALY_SECTION,
  TOGGLE_SECTION,
  CREATE_SECTION,
  DELETE_SECTION,
  CHANGE_SEED_CAMPAIGN,
  CHANGE_SEED_PAYMENT_TERM,
  CHANGE_SEED_PAYMENT_DATE,
  CHANGE_SEED_PRODUCT,
  CHANGE_SEED_VALUE,
  CHANGE_SEED_SUFFIX,
  CHANGE_SEED_BARTER,
  ADD_SEED_ROW,
  REMOVE_SEED_ROW,
  UPDATE_SEED_ROW,
  REMOVE_SEED_ALL_ROW,
  RECEIVE_SEED_CENARIO_SECTIONS,
  CLEAR_SEEDS_DATA,
};

const initialState = {
  sectionsTypes: ["Soja", "Sorgo", "Milho"],
  sections: [],
  isLoading: false,
};

const ProcessEncargos = (section, encargos, distrito) => {
  const encargosDates =
    section?.campanha?.groupedCampaign !== undefined
      ? encargos?.filter(
          (x) =>
            section?.campanha?.groupedCampaign ==
            x.assignmentNumber + "_" + x.materialGroupDscp
        )
      : [];
  const vencimento = section.vencimento;
  let rates = encargosDates
    .filter(
      (x) =>
        x.tableName == "A691" &&
        x.salesOfficeDscp == distrito &&
        x.conditionGroupDscp == vencimento
    )
    .sort((a, b) => a.rate - b.rate);

  if (rates?.length <= 0) {
    rates = encargosDates
      .filter(
        (x) => x.tableName == "A609" && x.conditionGroupDscp == vencimento
      )
      .sort((a, b) => a.rate - b.rate);

    if (rates?.length <= 0) {
      rates = encargosDates
        .filter(
          (x) =>
            x.tableName == "A687" &&
            x.salesOfficeDscp == distrito &&
            x.conditionGroupDscp == vencimento
        )
        .sort((a, b) => a.rate - b.rate);

      if (rates?.length <= 0) {
        rates = encargosDates
          .filter(
            (x) => x.tableName == "A602" && x.conditionGroupDscp == vencimento
          )
          .sort((a, b) => a.rate - b.rate);
      }
    }
  }

  return rates[0]?.rate ?? 0;
};

export const simulatorSeedsActions = {
  //#region Sections Management
  handleTemporalySection: () => async (dispatch) => {
    dispatch({ type: NEW_TEMPORALY_SECTION });
  },
  handleToggleSection: (id) => async (dispatch, getState) => {
    dispatch({ type: TOGGLE_SECTION, id });
  },
  handleCreateSection: (id, cultura) => async (dispatch) => {
    dispatch({ type: CREATE_SECTION, id, cultura });
  },
  handleRemoveSection: (id) => async (dispatch) => {
    dispatch({ type: DELETE_SECTION, id });
  },
  //#endregion Sections Management

  //#region Options Management
  handleChangeCampaign: (id, campaignId) => async (dispatch, getState) => {
    const campaign = getState().allcampanhas.AllSeedsCampaigns.find(
      (x) => x.campaignId == campaignId
    );

    dispatch({ type: CHANGE_SEED_CAMPAIGN, id, campaign });
  },
  handleChangePaymentTerm: (id, value) => async (dispatch, getState) => {
    dispatch({ type: CHANGE_SEED_PAYMENT_TERM, id, value });
  },
  handleChangePaymentDate:
    (
      id,
      value,
      groupId,
      groupedCampaign,
      territory,
      district,
      culture,
      condPagto,
      update = false
    ) =>
    async (dispatch, getState) => {
      const { oidc } = getState();

      const urlCampaign = `/api/simuladorProdutosSeeds?campanha=${groupedCampaign}&territorio=${territory}&clienteId=${groupId}&distrito=${district}&cultura=${culture}&condPagto=${condPagto}&dataPagto=${value}&update=${update}`;
      const optionsCampaign = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const urlAnalytics = `/api/SimuladorProdutosSeeds/analytics?campanha=${groupedCampaign}&territorio=${territory}&clienteId=${groupId}&distrito=${district}&cultura=${culture}&condPagto=${condPagto}&dataPagto=${value}`;
      const optionsAnalytics = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const urlDescontos = `/api/simuladorProdutosSeeds/descontos?campanha=${groupedCampaign}&territorio=${territory}&clienteId=${groupId}&distrito=${district}&cultura=${culture}&condPagto=${condPagto}&vencimento=${value}`;
      const optionsDescontos = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      dispatch({ type: CHANGE_LOAD_STATE, loading: true });

      const [responseProducts, responseAnalytics, responseDescontos] =
        await Promise.all([
          fetch(urlCampaign, optionsCampaign),
          fetch(urlAnalytics, optionsAnalytics),
          fetch(urlDescontos, optionsDescontos),
        ]);

      const [products, analyticsProducts, descontos] = await Promise.all([
        responseProducts.json(),
        responseAnalytics.json(),
        responseDescontos.json(),
      ]);

      dispatch({
        type: CHANGE_SEED_PAYMENT_DATE,
        id,
        value,
        analyticsProducts,
        products,
        descontos,
      });
    },
  handleChangeProduct: (sectionId, product) => async (dispatch, getState) => {
    dispatch({ type: CHANGE_SEED_PRODUCT, sectionId, product });
  },
  //#endregion Options Management

  //#region Row Management
  addAnalyticsRows:
    (id, rate, currency, condPagto) => async (dispatch, getState) => {
      const { simulator2, simuladorSeeds } = getState();
      const client = simulator2.clientes.client;

      const section = simuladorSeeds.sections.find((x) => x.id == id);
      const descontos = section.descontos;

      if (section && section.analyticsProducts.length > 0) {
        section.analyticsProducts.forEach((product) => {
          const recomendacoes = [];
          if (product.recomendacaoMarketing) {
            recomendacoes.push("Marketing");
          }
          if (product.guiaPosicionamento) {
            recomendacoes.push("Guia de Posicionamento");
          }
          if (product.geracaoDemanda) {
            recomendacoes.push("Geração Demanda");
          }

          dispatch({
            type: ADD_SEED_ROW,
            id,
            row: {
              analytics: true,
              produtoId: product.productId,
              produto: product.product,
              encargo: rate,
              cur: product.cur,

              recomendacoes: recomendacoes.join(","),
              brDscCtgCliMax: descontos.brDscCtgCliMax,
              brDscCtgCliTrMax: descontos.brDscCtgCliTrMax,
              brDscPreCampMax: descontos.brDscPreCampMax,
              brDscPreCvTrMax: descontos.brDscPreCvTrMax,
              brDscPenMax: descontos.brDscPenMax,
              brDscTrMax: descontos.brDscTrMax,
              brDscResCpMax: descontos.brDscResCpMax,
              brDscDirMax: descontos.brDscDirMax,
              dscSobDsmpMax: descontos.dscSobDsmpMax,
              dscGestInvMax: descontos.dscGestInvMax,
              brDscTgMax: descontos.brDscTgMax,

              motivo: product.motivo,
              moeda: currency,
              sufixos: product.sufixos,
            },
            groupId: client.grupoId,
            distrito: client.distrito,
            classificacoes: client.classificacaoClienteSeeds.split(","),
            condPagto: condPagto,
          });
        });
      }

      dispatch({ type: CREATE_SEED_SIMULATION, id });
    },
  addRow:
    (
      id,
      product,
      rate,
      currency,
      descontos,
      campaign,
      territory,
      groupId,
      district,
      culture,
      condPagto,
      dataPagto
    ) =>
    async (dispatch, getState) => {
      const { oidc, simulator2 } = getState();
      const client = simulator2.clientes.client;

      dispatch({ type: CHANGE_LOAD_STATE, loading: true });

      const url = `/api/SimuladorProdutosSeeds/sufixos?produto=${product.product}&campanha=${campaign}&territorio=${territory}&clienteId=${groupId}&distrito=${district}&cultura=${culture}&condPagto=${condPagto}&dataPagto=${dataPagto}`;
      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      const suffixs = await response.json();

      if (suffixs.length == 0) {
        dispatch({
          type: "CALL_ALERT",
          title: "Alerta",
          message:
            "O produto escolhido não possui preço cadastrado. Entre em contato com CSR em caso de dúvida.",
          responseType: "info",
        });
        dispatch({ type: CHANGE_LOAD_STATE, loading: false });
        return;
      }

      if (product) {
        const recomendacoes = [];
        if (product.recomendacaoMarketing) {
          recomendacoes.push("Marketing");
        }
        if (product.guiaPosicionamento) {
          recomendacoes.push("Guia de Posicionamento");
        }
        if (product.geracaoDemanda) {
          recomendacoes.push("Geração Demanda");
        }

        dispatch({
          type: ADD_SEED_ROW,
          id,
          row: {
            produtoId: product.productId,
            produto: product.product,
            encargo: rate,
            cur: product.cur,

            recomendacoes: recomendacoes.join(","),
            brDscCtgCliMax: descontos.brDscCtgCliMax,
            brDscCtgCliTrMax: descontos.brDscCtgCliTrMax,
            brDscPreCampMax: descontos.brDscPreCampMax,
            brDscPreCvTrMax: descontos.brDscPreCvTrMax,
            brDscPenMax: descontos.brDscPenMax,
            brDscTrMax: descontos.brDscTrMax,
            brDscResCpMax: descontos.brDscResCpMax,
            brDscDirMax: descontos.brDscDirMax,
            dscSobDsmpMax: descontos.dscSobDsmpMax,
            dscGestInvMax: descontos.dscGestInvMax,
            brDscTgMax: descontos.brDscTgMax,

            motivo: product.motivo,
            moeda: currency,
            sufixos: suffixs,
          },
          groupId: client.grupoId,
          distrito: client.distrito,
          classificacoes: client.classificacaoClienteSeeds.split(","),
          condPagto: condPagto,
        });
      }
    },
  updateRows: (id, rate, currency, condPagto) => async (dispatch, getState) => {
    const { simuladorSeeds, simulator2, seedsEmbalagens } = getState();
    const { flexibilidade, gmTarget } = simulator2.nivelSemaforo;

    const client = simulator2.clientes.client;

    const section = simuladorSeeds.sections.find((x) => x.id == id);
    const descontos = section.descontos;

    if (section && section.produtos.length > 0) {
      section.rows.forEach((row) => {
        const updatedProduct = section.produtos.find(
          (x) => x.product == row.produto
        );

        if (updatedProduct !== null) {
          const recomendacoes = [];
          if (updatedProduct.recomendacaoMarketing) {
            recomendacoes.push("Marketing");
          }
          if (updatedProduct.guiaPosicionamento) {
            recomendacoes.push("Guia de Posicionamento");
          }
          if (updatedProduct.geracaoDemanda) {
            recomendacoes.push("Geração Demanda");
          }

          dispatch({
            type: UPDATE_SEED_ROW,
            id,
            rowId: row.id,
            row: {
              encargo: rate,
              cur: updatedProduct.cur,
              recomendacoes: recomendacoes.join(","),

              brDscCtgCliMax: descontos.brDscCtgCliMax,
              brDscCtgCliTrMax: descontos.brDscCtgCliTrMax,
              brDscPreCampMax: descontos.brDscPreCampMax,
              brDscPreCvTrMax: descontos.brDscPreCvTrMax,
              brDscPenMax: descontos.brDscPenMax,
              brDscTrMax: descontos.brDscTrMax,
              brDscResCpMax: descontos.brDscResCpMax,
              brDscDirMax: descontos.brDscDirMax,
              dscSobDsmpMax: descontos.dscSobDsmpMax,
              dscGestInvMax: descontos.dscGestInvMax,
              brDscTgMax: descontos.brDscTgMax,

              moeda: currency,
              sufixos: updatedProduct.sufixos,
            },
            nivelSemaforoFlex: flexibilidade,
            nivelSemaforoGm: gmTarget,
            groupId: client.grupoId,
            distrito: client.distrito,
            classificacoes: client.classificacaoClienteSeeds.split(","),
            condPagto: condPagto,
            embalagens: seedsEmbalagens?.embalagens,
          });
        }
      });
    }
  },
  removeRow:
    (sectionId, rowId, motivo = "", condPagto) =>
    async (dispatch, getState) => {
      const { simulator2 } = getState();

      const client = simulator2.clientes.client;
      dispatch({
        type: REMOVE_SEED_ROW,
        sectionId,
        rowId,
        motivo,
        groupId: client.grupoId,
        distrito: client.distrito,
        classificacoes: client.classificacaoClienteSeeds.split(","),
        condPagto: condPagto,
      });
    },
  removeAllRows: () => async (dispatch, getState) => {
    dispatch({ type: REMOVE_SEED_ALL_ROW });
  },
  clearData: () => async (dispatch) => {
    dispatch({ type: CLEAR_SEEDS_DATA });
  },
  //#endregion Row Management

  //#region LoadManager
  handleLoadAndCalc: (cenario, encargos) => async (dispatch, getState) => {
    const { oidc, simulator2, seedsEmbalagens } = getState();

    dispatch({ type: CHANGE_LOAD_STATE, loading: true });
    dispatch({ type: CLEAR_SEEDS_DATA });

    const distrito = cenario.distrito;
    const territorio = cenario.territorio;
    const groupId = cenario.clienteId;
    const classificacoes =
      simulator2.clientes.client?.classificacaoClienteSeeds?.split(",");

    for (const section of cenario.cenarioV2Seeds) {
      const rate = ProcessEncargos(section, encargos, distrito);

      const urlProdutos = `/api/simuladorProdutosSeeds?campanha=${section.campanhaGroup}&territorio=${territorio}&clienteId=${groupId}&distrito=${distrito}&cultura=${section.cultura}&condPagto=${section.condicao}&dataPagto=${section.vencimento}`;
      const urlDescontos = `/api/simuladorProdutosSeeds/descontos?campanha=${section.campanhaGroup}&territorio=${territorio}&clienteId=${groupId}&distrito=${distrito}&cultura=${section.cultura}&condPagto=${section.condicao}&vencimento=${section.vencimento}`;

      const optionsProdutos = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };
      const optionsDescontos = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const [responseProducts, responseDescontos] = await Promise.all([
        fetch(urlProdutos, optionsProdutos),
        fetch(urlDescontos, optionsDescontos),
      ]);

      const [products, descontos] = await Promise.all([
        responseProducts.json(),
        responseDescontos.json(),
      ]);

      dispatch({
        type: RECEIVE_SEED_CENARIO_SECTIONS,
        section,
        products,
        descontos,
        rate,
        groupId,
        distrito,
        classificacoes,
        embalagens: seedsEmbalagens?.embalagens ?? [],
      });
    }

    dispatch({ type: CHANGE_LOAD_STATE, loading: false });
  },
  //#endregion

  handleChangeBarter: (id, value) => async (dispatch, getState) => {
    dispatch({ type: CHANGE_SEED_BARTER, id, value });
  },
  handleChangeAdimplencia: (id, value) => async (dispatch, getState) => {
    dispatch({ type: CHANGE_SEED_ADIMPLENCIA, id, value });
  },
  handleChangeValue:
    (
      sectionId,
      id,
      param,
      value,
      groupId,
      condPagto,
      distrito,
      classificacoes
    ) =>
    async (dispatch, getState) => {
      const { simulator2 } = getState();
      const { flexibilidade, gmTarget } = simulator2.nivelSemaforo;

      dispatch({
        type: CHANGE_SEED_VALUE,
        sectionId,
        id,
        param,
        value,
        groupId,
        condPagto,
        distrito,
        classificacoes,
        nivelSemaforoFlex: flexibilidade,
        nivelSemaforoGm: gmTarget,
      });
    },
  handleChangeSuffix: (sectionId, id, suffix) => async (dispatch, getState) => {
    const { seedsEmbalagens, simulator2, simuladorSeeds } = getState();

    const client = simulator2.clientes.client;
    const classificacoes = client?.classificacaoClienteSeeds?.split(",");

    // Procurar se produto com esse sufixo já está na lista
    const sectionIndex = simuladorSeeds.sections?.findIndex(
      (x) => x.id == sectionId
    );
    if (sectionIndex !== -1) {
      const rowIndex = simuladorSeeds.sections[sectionIndex]["rows"]?.findIndex(
        (x) => x.id == id
      );
      if (rowIndex !== -1) {
        const row = simuladorSeeds.sections[sectionIndex]["rows"][rowIndex];
        const equalIndex = simuladorSeeds.sections[sectionIndex][
          "rows"
        ]?.findIndex(
          (x) => x.produto == row.produto && x.sufixo?.sufixo == suffix?.sufixo
        );

        if (equalIndex !== -1) {
          const oldRow =
            simuladorSeeds.sections[sectionIndex]["rows"][equalIndex];
          if (oldRow.excluido === undefined || !oldRow.excluido) {
            dispatch({
              type: "CALL_ALERT",
              responseType: "info",
              title: "Sufixo já adicionado",
              message:
                "O Produto com este sufixo já está adicionado ao seu pedido.",
            });
            return;
          }
        }
      }
    }

    dispatch({
      type: CHANGE_SEED_SUFFIX,
      sectionId,
      id,
      suffix,
      groupId: client?.grupoId,
      distrito: client?.distrito,
      classificacoes,
      embalagens: seedsEmbalagens?.embalagens,
    });
  },
};

export const calcRow = (row, nivelSemaforoFlex, nivelSemaforoGm) => {
  //Validar se os descontos estão dentro do valor máximo permitido
  row.brDscCtgCli =
    row.brDscCtgCli > Math.abs(row.brDscCtgCliMax)
      ? Math.abs(row.brDscCtgCliMax)
      : row.brDscCtgCli;
  row.brDscCtgCliTr =
    row.brDscCtgCliTr > Math.abs(row.brDscCtgCliTrMax)
      ? Math.abs(row.brDscCtgCliTrMax)
      : row.brDscCtgCliTr;
  row.brDscPreCamp =
    row.brDscPreCamp > Math.abs(row.brDscPreCampMax)
      ? Math.abs(row.brDscPreCampMax)
      : row.brDscPreCamp;
  row.brDscPreCvTr =
    row.brDscPreCvTr > Math.abs(row.brDscPreCvTrMax)
      ? Math.abs(row.brDscPreCvTrMax)
      : row.brDscPreCvTr;
  row.brDscPen =
    row.brDscPen > Math.abs(row.brDscPenMax)
      ? Math.abs(row.brDscPenMax)
      : row.brDscPen;
  row.brDscVol =
    row.brDscVol > Math.abs(row.brDscVolMax)
      ? Math.abs(row.brDscVolMax)
      : row.brDscVol;
  row.brDscTr =
    row.brDscTr > Math.abs(row.brDscTrMax)
      ? Math.abs(row.brDscTrMax)
      : row.brDscTr;
  row.brDscResCp =
    row.brDscResCp > Math.abs(row.brDscResCpMax)
      ? Math.abs(row.brDscResCpMax)
      : row.brDscResCp;
  row.brDscDir =
    row.brDscDir > Math.abs(row.brDscDirMax)
      ? Math.abs(row.brDscDirMax)
      : row.brDscDir;
  row.dscSobDsmp =
    row.dscSobDsmp > Math.abs(row.dscSobDsmpMax)
      ? Math.abs(row.dscSobDsmpMax)
      : row.dscSobDsmp;
  row.dscGestInv =
    row.dscGestInv > Math.abs(row.dscGestInvMax)
      ? Math.abs(row.dscGestInvMax)
      : row.dscGestInv;
  row.brDscVex =
    row.brDscVex > Math.abs(row.brDscVexMax)
      ? Math.abs(row.brDscVexMax)
      : row.brDscVex;
  row.brDscTg =
    row.brDscTg > Math.abs(row.brDscTgMax)
      ? Math.abs(row.brDscTgMax)
      : row.brDscTg;

  //Calcular preço corteva
  row.priceRate = GetSafeNumber(
    row.precoSemente + row.precoSemente * ((row.encargo ?? 0) / 100)
  );
  row.priceStRate = GetSafeNumber(
    row.precoSt + row.precoSt * ((row.encargo ?? 0) / 100)
  );
  row.subTotalPrice = GetSafeNumber(row.priceRate + row.priceStRate);

  row.priceBrDscCtgCli = GetSafeNumber(
    row.priceRate * ((row.brDscCtgCli ?? 0) / 100)
  );
  row.priceBrDscCtgCliTr = GetSafeNumber(
    row.priceStRate * ((row.brDscCtgCliTr ?? 0) / 100)
  );
  row.priceBrDscPreCamp = GetSafeNumber(
    row.priceRate * ((row.brDscPreCamp ?? 0) / 100)
  );
  row.priceBrDscPreCvTr = GetSafeNumber(
    row.priceStRate * ((row.brDscPreCvTr ?? 0) / 100)
  );
  row.priceBrDscPen = GetSafeNumber(
    row.priceRate * ((row.brDscPen ?? 0) / 100)
  );
  row.priceBrDscTr = GetSafeNumber(
    row.priceStRate * ((row.brDscTr ?? 0) / 100)
  );
  row.priceBrDscVol = GetSafeNumber(
    row.priceRate * ((row.brDscVol ?? 0) / 100)
  );
  row.priceBrDscResCp = GetSafeNumber(
    row.priceRate * ((row.brDscResCp ?? 0) / 100)
  );
  row.priceBrDscDir = GetSafeNumber(
    row.priceRate * ((row.brDscDir ?? 0) / 100)
  );
  row.priceDscSobDsmp = GetSafeNumber(
    row.subTotalPrice * ((row.dscSobDsmp ?? 0) / 100)
  );
  row.priceDscGestInv = GetSafeNumber(
    row.priceRate * ((row.dscGestInv ?? 0) / 100)
  );
  row.priceBrDscVex = GetSafeNumber(
    row.subTotalPrice * ((row.brDscVex ?? 0) / 100)
  );
  row.priceBrDscTg = GetSafeNumber(
    row.subTotalPrice * ((row.brDscTg ?? 0) / 100)
  );

  row.precoCorteva =
    row.subTotalPrice +
    GetSafeNumber(
      (row.priceBrDscCtgCli +
        row.priceBrDscCtgCliTr +
        row.priceBrDscPreCamp +
        row.priceBrDscPreCvTr +
        row.priceBrDscPen +
        row.priceBrDscTr +
        row.priceBrDscVol +
        row.priceBrDscResCp +
        row.priceBrDscDir +
        row.priceDscSobDsmp +
        row.priceDscGestInv +
        row.priceBrDscVex +
        row.priceBrDscTg) *
        -1
    );
  row.precoCorteva = Number(row.precoCorteva.toFixed(2));
  row.precoNegociadoMax = row.precoCorteva;

  //Calcular outras informações
  const dscCpa = GetSafeNumber(row.precoNegociado - row.precoCorteva);
  row.brDscCpa =
    row.salesCount !== null && row.salesCount != 1
      ? GetSafeNumber(dscCpa / row.salesCount)
      : dscCpa;

  row.valorFinalCorteva = GetSafeNumber(row.precoCorteva * row.volume);
  row.valorFinalNegociado = GetSafeNumber(row.precoNegociado * row.volume);

  row.salesDeduction =
    GetSafeNumber(row.valorFinalNegociado * (row.salesDeductionPer / 100)) * -1;
  row.netSales = GetSafeNumber(row.valorFinalNegociado + row.salesDeduction);

  row.cogs = GetSafeNumber(row.cogsSeeds * row.volume) * -1;
  row.cogsSt = GetSafeNumber(row.volume * row.cogsStPer) * -1;

  row.gmStd = GetSafeNumber(row.netSales + row.cogs + row.cogsSt);
  row.gmPer = GetSafeNumber(row.gmStd / row.netSales) * 100;

  row.perAtingidoGm = GetSafeNumber((row.gmPer / row.gmTarget) * 100);
  row.perAtingidoCpa = GetSafeNumber((row.brDscCpa / row.maxDscCpa) * 100);

  row.realVolume = GetSafeNumber(
    row.salesCount !== null ? row.salesCount * row.volume : row.volume
  );
  row.totalCpaScDsc = GetSafeNumber(row.brDscCpa * row.realVolume);

  try {
    const nivelFlexibilidade = nivelSemaforoFlex.find((x) => {
      var ssMin = x.minimo;
      var vSsMin = ssMin == null || row.perAtingidoCpa > ssMin;
      var ssMax = x.maximo;
      var vSsMax = ssMax == null || row.perAtingidoCpa <= ssMax;

      return vSsMin && vSsMax;
    });

    row.scoreProduto = nivelFlexibilidade.cor;
  } catch (e) {}

  try {
    const nivelGm = nivelSemaforoGm.find((x) => {
      var ssMin = x.minimo;
      var vSsMin = ssMin == null || row.perAtingidoGm >= ssMin;
      var ssMax = x.maximo;
      var vSsMax = ssMax == null || row.perAtingidoGm < ssMax;

      return vSsMin && vSsMax;
    });

    row.scoreGM = nivelGm.cor;
  } catch (e) {}

  return row;
};

export const findDscVolume = (
  rows,
  groupId,
  condPagto,
  distrito = "",
  classificacoes = [],
  brDscVolList
) => {
  if (brDscVolList != undefined) {
    const sectionVolume = rows
      .filter((x) => !x.excluido)
      .reduce(
        (acc, x) =>
          acc +
          GetSafeNumber(
            x.salesCount !== null ? x.salesCount * x.volume : x.volume
          ),
        0
      );

    let sortByRate = true;
    let brDscVol = brDscVolList.filter(
      (x) => x.tableName == "A615" && x.groupId == groupId
    );

    if (brDscVol.length <= 0) {
      brDscVol = brDscVolList.filter(
        (x) =>
          x.tableName == "A695" &&
          x.paymentTerms == condPagto &&
          x.salesOfficeDscp == distrito
      );

      if (brDscVol.length <= 0) {
        brDscVol = brDscVolList.filter(
          (x) =>
            x.tableName == "A686" &&
            classificacoes.includes(x.customerGroupId) &&
            x.salesOfficeDscp == distrito
        );

        if (brDscVol.length <= 0) {
          brDscVol = brDscVolList
            .filter(
              (x) =>
                x.tableName == "A642" &&
                x.salesOfficeDscp == distrito &&
                sectionVolume >= x.conditionScaleQuantity
            )
            .sort((a, b) => b.sequentialNumberScale - a.sequentialNumberScale);

          if (brDscVol.length <= 0) {
            brDscVol = brDscVolList.filter((x) => x.tableName == "A607");
          } else {
            sortByRate = false;
          }
        }
      }
    }

    const rate = sortByRate
      ? brDscVol.sort((a, b) => b.rate - a.rate)[0].rate
      : brDscVol[0].rate;
    rows.map((x) => (x.brDscVolMax = rate));
  }

  return rows;
};

export const reducer = (state = initialState, action) => {
  state = state || initialState;

  let newSections;
  let sectionIndex;
  let sectionCultureIndex;
  let rowIndex;
  let newRow;

  switch (action.type) {
    case TOGGLE_SECTION:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.id
      );

      if (sectionIndex !== -1) {
        newSections[sectionIndex].isOpen = newSections[sectionIndex].isOpen
          ? !newSections[sectionIndex].isOpen
          : true;
      }

      return {
        ...state,
        sections: newSections,
      };
    case NEW_TEMPORALY_SECTION:
      return {
        ...state,
        sections: [...state.sections, { id: -1, isTemporaly: true }],
      };
    case CREATE_SECTION:
      newSections = [...state.sections];
      sectionCultureIndex = newSections.findIndex(
        (section) => section.cultura === action.cultura
      );

      if (sectionCultureIndex !== -1) return;

      sectionIndex = newSections.findIndex(
        (section) => section.id === action.id
      );
      if (sectionIndex !== -1) {
        const maxId = newSections.reduce(
          (max, section) => Math.max(max, section?.id),
          0
        );
        newSections[sectionIndex] = { id: maxId + 1, cultura: action.cultura };
        newSections[sectionIndex].isOpen = true;
      }

      return {
        ...state,
        sections: newSections,
      };
    case DELETE_SECTION:
      newSections = state.sections.filter(
        (section) => section.id !== action.id
      );
      return {
        ...state,
        sections: newSections,
      };
    case CHANGE_SEED_CAMPAIGN:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.id
      );

      if (sectionIndex !== -1) {
        const updatedSection = { ...newSections[sectionIndex] };
        updatedSection["analyticsProducts"] = [];
        updatedSection["descontos"] = [];
        updatedSection["produtos"] = [];
        updatedSection["vencimento"] = "";
        updatedSection["condicao"] = "";
        updatedSection["bonusAdimplencia"] = 0;
        updatedSection["valorizacaoBarter"] = 0;
        updatedSection["dataSelecionada"] = false;
        updatedSection["rows"] = [];

        updatedSection["campanhaId"] = action.campaign.campaignId;
        updatedSection["campanha"] = action.campaign;
        updatedSection["campanhaGroup"] = action.campaign.groupedCampaign;
        updatedSection["moeda"] = action.campaign.currency;

        newSections[sectionIndex] = updatedSection;
      }
      return {
        ...state,
        sections: newSections,
        isLoading: false,
      };
    case CHANGE_SEED_PAYMENT_TERM:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.id
      );

      if (sectionIndex !== -1) {
        const updatedSection = { ...newSections[sectionIndex] };
        updatedSection["condicao"] = action.value;
        updatedSection["bonusAdimplencia"] = 0;
        updatedSection["valorizacaoBarter"] = 0;

        newSections[sectionIndex] = updatedSection;
      }

      return {
        ...state,
        sections: newSections,
        isLoading: false,
      };
    case CHANGE_SEED_BARTER:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.id
      );

      if (sectionIndex !== -1) {
        const updatedSection = { ...newSections[sectionIndex] };
        updatedSection["valorizacaoBarter"] = action.value;
        newSections[sectionIndex] = updatedSection;
      }

      return {
        ...state,
        sections: newSections,
        isLoading: false,
      };
    case CHANGE_SEED_ADIMPLENCIA:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.id
      );

      if (sectionIndex !== -1) {
        const updatedSection = { ...newSections[sectionIndex] };
        updatedSection["bonusAdimplencia"] = action.value;
        newSections[sectionIndex] = updatedSection;
      }

      return {
        ...state,
        sections: newSections,
        isLoading: false,
      };
    case CHANGE_SEED_PAYMENT_DATE:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex((x) => x.id === action.id);

      if (sectionIndex !== -1) {
        const updatedSection = { ...newSections[sectionIndex] };
        updatedSection["vencimento"] = action.value;
        updatedSection["dataSelecionada"] = true;

        updatedSection["produtos"] = action.products;
        updatedSection["analyticsProducts"] = action.analyticsProducts;
        updatedSection["descontos"] = action.descontos;

        newSections[sectionIndex] = updatedSection;
      }

      return {
        ...state,
        sections: newSections,
        isLoading: false,
      };
    case CHANGE_SEED_PRODUCT:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.sectionId
      );

      if (sectionIndex !== -1) {
        newSections[sectionIndex]["product"] = action.product;
      }

      return {
        ...state,
        sections: newSections,
      };
    case CREATE_SEED_SIMULATION:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.id
      );

      if (sectionIndex !== -1) {
        newSections[sectionIndex]["foiCriado"] = true;
      }

      return {
        ...state,
        sections: newSections,
      };
    case ADD_SEED_ROW:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.id
      );

      if (sectionIndex !== -1) {
        const updatedSection = { ...newSections[sectionIndex] };
        let maxId = 0;

        if (updatedSection["rows"] === undefined) {
          updatedSection["rows"] = [
            { ...action.row, id: maxId + 1, section: updatedSection.cultura },
          ];
        } else {
          maxId =
            updatedSection["rows"]?.reduce(
              (max, row) => Math.max(max, row?.id),
              0
            ) ?? 0;
          updatedSection["rows"] = [
            ...updatedSection["rows"],
            { ...action.row, id: maxId + 1, section: updatedSection.cultura },
          ];
        }

        updatedSection["rows"] = findDscVolume(
          updatedSection["rows"],
          action.groupId,
          action.condPagto,
          action.distrito,
          action.classificacoes,
          updatedSection["descontos"].brDscVolList
        );
        newSections[sectionIndex] = updatedSection;
      }

      return {
        ...state,
        sections: newSections,
        isLoading: false,
      };
    case UPDATE_SEED_ROW:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.id
      );

      if (sectionIndex !== -1) {
        const updatedSection = { ...newSections[sectionIndex] };
        const rowIndex = updatedSection.rows?.findIndex(
          (x) => x.id === action.rowId
        );

        if (rowIndex !== -1) {
          let updatedRow = updatedSection.rows[rowIndex];
          updatedRow = { ...updatedSection.rows[rowIndex], ...action.row };
          console.log(updatedRow);

          const suffix = action.row.sufixos.find(
            (x) => x?.sufixo?.sufixo == updatedRow?.sufixo?.sufixo
          );

          if (suffix != null) {
            const embalagem = action.embalagens.find(
              (x) =>
                x.cultura == updatedSection.cultura &&
                x.salesUnit == suffix?.salesUnit
            );

            updatedRow["sufixo"] = suffix;
            updatedRow["maxDscCpa"] = GetSafeNumber(suffix?.dscMaxCPA);
            updatedRow["brDscVexMax"] = GetSafeNumber(suffix?.brDscVexMax);
            updatedRow["gmTarget"] = GetSafeNumber(suffix?.gmTarget);
            updatedRow["precoSemente"] = GetSafeNumber(suffix?.precoSemente);
            updatedRow["precoSt"] = GetSafeNumber(suffix?.precoTratamento);
            updatedRow["frb"] = suffix?.frb;
            updatedRow["cota"] = GetSafeNumber(suffix?.cota);
            updatedRow["cogsSeeds"] = GetSafeNumber(suffix?.cogs);
            updatedRow["cogsStPer"] = GetSafeNumber(suffix?.cogsSt);
            updatedRow["salesDeductionPer"] = GetSafeNumber(
              suffix?.salesDeduction
            );
            updatedRow["salesUnit"] = suffix.salesUnit;
            updatedRow["salesCount"] = embalagem.quantidade;
          }

          updatedRow = calcRow(
            updatedRow,
            action.nivelSemaforoFlex,
            action.nivelSemaforoGm
          );
          updatedSection.rows[rowIndex] = updatedRow;

          updatedSection["rows"] = findDscVolume(
            updatedSection["rows"],
            action.groupId,
            action.condPagto,
            action.distrito,
            action.classificacoes,
            updatedSection["descontos"].brDscVolList
          );
          newSections[sectionIndex] = updatedSection;
        }
      }

      return {
        ...state,
        sections: newSections,
      };
    case REMOVE_SEED_ROW:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.sectionId
      );

      if (sectionIndex !== -1) {
        rowIndex = newSections[sectionIndex]["rows"].findIndex(
          (row) => row.id === action.rowId
        );

        if (rowIndex !== -1) {
          newRow = newSections[sectionIndex]["rows"][rowIndex];
          newRow.motivo = newRow.analytics ? newRow.motivo : action.motivo;
          newRow.excluido = true;
        }

        newSections[sectionIndex]["rows"] = findDscVolume(
          newSections[sectionIndex]["rows"],
          action.groupId,
          action.condPagto,
          action.distrito,
          action.classificacoes,
          newSections[sectionIndex]["descontos"].brDscVolList
        );
      }

      return {
        ...state,
        sections: newSections,
      };
    case CHANGE_SEED_VALUE:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.sectionId
      );

      if (sectionIndex !== -1) {
        rowIndex = newSections[sectionIndex]["rows"].findIndex(
          (row) => row.id === action.id
        );

        if (rowIndex !== -1) {
          newRow = newSections[sectionIndex]["rows"][rowIndex];
          newRow[action.param] = action.value;
          newRow = calcRow(
            newRow,
            action.nivelSemaforoFlex,
            action.nivelSemaforoGm
          );
          newSections[sectionIndex]["rows"][rowIndex] = newRow;
        }

        newSections[sectionIndex]["rows"] = findDscVolume(
          newSections[sectionIndex]["rows"],
          action.groupId,
          action.condPagto,
          action.distrito,
          action.classificacoes,
          newSections[sectionIndex]["descontos"].brDscVolList
        );
      }

      return {
        ...state,
        sections: newSections,
      };

    case RECEIVE_SEED_CENARIO_SECTIONS:
      const sections =
        state.sections === null || state.sections === undefined
          ? []
          : state.sections;
      const section = action.section;

      try {
        section.produtos = action.products ? action.products : null;
        section.descontos = action.descontos ? action.descontos : null;

        section.rows = findDscVolume(
          section.rows,
          action.groupId,
          section.condicao,
          action.distrito,
          action.classificacoes,
          section.descontos.brDscVolList
        );
        section.rows = section.rows.map((x) => {
          const product = section.produtos?.find((y) => y.product == x.produto);
          const suffix =
            x.sufixos?.length > 0
              ? x.sufixos.find((y) => y.sufixo == x.sufixo)
              : null;

          const recomendacoes = [];
          if (product) {
            if (product.recomendacaoMarketing) {
              recomendacoes.push("Marketing");
            }
            if (product.guiaPosicionamento) {
              recomendacoes.push("Guia de Posicionamento");
            }
            if (product.geracaoDemanda) {
              recomendacoes.push("Geração Demanda");
            }
          }

          const embalagem = action.embalagens.find(
            (x) =>
              x.cultura == section.cultura && x.salesUnit == suffix?.salesUnit
          );
          const row = calcRow({
            ...x,
            encargo: action.rate,
            cur: product?.cur,

            recomendacoes: recomendacoes.join(","),
            brDscCtgCliMax: section.descontos.brDscCtgCliMax,
            brDscCtgCliTrMax: section.descontos.brDscCtgCliTrMax,
            brDscPreCampMax: section.descontos.brDscPreCampMax,
            brDscPreCvTrMax: section.descontos.brDscPreCvTrMax,
            brDscPenMax: section.descontos.brDscPenMax,
            brDscTrMax: section.descontos.brDscTrMax,
            brDscResCpMax: section.descontos.brDscResCpMax,
            brDscDirMax: section.descontos.brDscDirMax,
            dscSobDsmpMax: section.descontos.dscSobDsmpMax,
            dscGestInvMax: section.descontos.dscGestInvMax,
            brDscTgMax: section.descontos.brDscTgMax,

            sufixo: suffix,
            maxDscCpa: GetSafeNumber(suffix?.dscMaxCPA),
            gmTarget: GetSafeNumber(suffix?.gmTarget),
            precoSemente: GetSafeNumber(suffix?.precoSemente),
            precoSt: GetSafeNumber(suffix?.precoTratamento),
            frb: suffix?.frb,
            cota: GetSafeNumber(suffix?.cota),
            cogsSeeds: GetSafeNumber(suffix?.cogs),
            cogsStPer: GetSafeNumber(suffix?.cogsSt),
            salesDeductionPer: GetSafeNumber(suffix?.salesDeduction),
            salesUnit: suffix?.salesUnit,
            salesCount: embalagem?.quantidade,
          });

          return row;
        });

        sections.push(section);
      } catch {}

      return {
        ...state,
        sections: sections,
      };
    case CHANGE_SEED_SUFFIX:
      newSections = [...state.sections];
      sectionIndex = newSections.findIndex(
        (section) => section.id === action.sectionId
      );

      if (sectionIndex !== -1) {
        rowIndex = newSections[sectionIndex]["rows"].findIndex(
          (row) => row.id === action.id
        );

        if (rowIndex !== -1) {
          newRow = newSections[sectionIndex]["rows"][rowIndex];

          newRow["sufixo"] = action.suffix;
          newRow["maxDscCpa"] = action.suffix?.dscMaxCPA;
          newRow["brDscVexMax"] = action.suffix?.brDscVexMax;
          newRow["gmTarget"] = action.suffix?.gmTarget;
          newRow["precoSemente"] = action.suffix?.precoSemente;
          newRow["precoSt"] = action.suffix?.precoTratamento;
          newRow["frb"] = action.suffix?.frb;
          newRow["cota"] = action.suffix?.cota;
          newRow["cogsSeeds"] = action.suffix?.cogs;
          newRow["cogsStPer"] = action.suffix?.cogsSt;
          newRow["salesDeductionPer"] = action.suffix?.salesDeduction;
          newRow["salesUnit"] = action.suffix?.salesUnit;

          const embalagem = action.embalagens.find(
            (x) =>
              x.cultura == newSections[sectionIndex].cultura &&
              x.salesUnit == action.suffix?.salesUnit
          );
          newRow["salesCount"] = embalagem.quantidade;

          newRow = calcRow(
            newRow,
            action.nivelSemaforoFlex,
            action.nivelSemaforoGm
          );
          newSections[sectionIndex]["rows"][rowIndex] = newRow;
          newSections[sectionIndex]["rows"] = findDscVolume(
            newSections[sectionIndex]["rows"],
            action.groupId,
            newSections[sectionIndex].condicao,
            action.distrito,
            action.classificacoes,
            newSections[sectionIndex].descontos.brDscVolList
          );
        }
      }
      return {
        ...state,
        sections: newSections,
      };
    case CHANGE_LOAD_STATE:
      return {
        ...state,
        isLoading: action.loading,
      };
    case CLEAR_SEEDS_DATA:
      return {
        ...state,
        sections: [],
      };
    default:
      return state;
  }
};
