import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { userActions } from '../../../../actions/user.actions';
import { adminUserActions } from "../../../../actions/admin/user.actions";
import { adminStockActions } from "../../../../actions/admin/stock.actions";
import { visuallyHidden } from '@mui/utils';
import AllowUseNavigate from '../../../Navigate.jsx';
import SimpleBoxError from "../../../SimpleBoxError.jsx";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import CreateIcon from '@mui/icons-material/Create';
import Grid from "@mui/material/Grid";
import TableSortLabel from '@mui/material/TableSortLabel';
import { IconButton, InputBase } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { formatBoolean, formatDate, getComparator, stableSort } from "../../../../helpers/utils.js";

const columns = [
  { id: 'ano', label: 'Ano', align: 'center' },
  { id: 'trimestre', label: 'Trimestre', align: 'center' },
  { id: 'dataInicio', label: 'Data Início', type: 'date', align: 'center' },
  { id: 'dataFim', label: 'Data Fim', type: 'date', align: 'center' },
  { id: 'ativo', label: 'Ativo', type: 'boolean', align: 'center' },
  { id: 'porRaiz', label: 'Por Raiz', type: 'boolean', align: 'center' },
];

const headCells = [
  {
    id: 'ano',
    align: 'center',
    label: 'Ano',
    sortable: true
  },
  {
    id: 'trimestre',
    align: 'center',
    label: 'Mês',
    sortable: true
  },
  {
    id: 'dataInicio',
    align: 'center',
    label: 'Data Início',
    sortable: true
  },
  {
    id: 'dataFim',
    align: 'center',
    label: 'Data Fim',
    sortable: true
  },
  {
    id: 'ativo',
    align: 'center',
    label: 'Em Execução',
    sortable: true
  },
  {
    id: 'porRaiz',
    align: 'center',
    label: 'Por Raiz',
    sortable: true
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "th": {
          color: "#FFF",
          fontWeight: "bold",
          backgroundColor: "var(--cort-blue) !important"
        },
      }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            size="medium"
            sx={{
              '*': {
                color: '#fff !important',
                '&:hover': {
                  color: '#FFF !important',
                },
              },
            }}
          >
            {headCell.sortable ?
              <TableSortLabel
                active={orderBy === headCell.id}
                hideSortIcon={orderBy !== headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              : headCell.label
            }

          </TableCell>
        ))}
        <TableCell
          align={'center'}
          size="medium"
          sx={{
            '*': {
              color: '#fff !important',
              '&:hover': {
                color: '#FFF !important',
              },
            },
          }}
        >
          Opções
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({ handleSearchText = (event) => { } }) {
  return (
    <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }}
      spacing={{ xs: 1, sm: 1.5, md: 2 }}
      justifyContent={{ xs: "center", sm: "center", md: "space-around" }}
      alignItems={{ xs: "flex-start", sm: "flex-start", md: "center" }}
      sx={{ mb: "16px" }}
    >
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Pesquise por Nome"
          onChange={handleSearchText}
          inputProps={{ "aria-label": "Pesquise por Nome" }}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </Stack>
  );
}

function StockTable(props) {
  const { rows, actions, handleSearchText } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('nome');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <EnhancedTableToolbar handleSearchText={handleSearchText} />
      <TableContainer component={Paper}>
        <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {(rows != null && rowsPerPage > 0
              ? stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : stableSort(rows, getComparator(order, orderBy))
            ).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];

                    if (column.type === "date") {
                      value = formatDate(value);
                    }
                    else if (column.type === "boolean") {
                      value = formatBoolean(value);
                    }

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  })}
                  <TableCell align="center">
                    <Grid container spacing={2} >
                      <Grid item xs={6}>
                        <Link to={`/Admin-Period-Form?PeriodId=${row["id"]}`} style={{ width: "100%" }}>
                          <Button variant="text" startIcon={<CreateIcon />}>Editar</Button>
                        </Link>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => row["ativo"] ? actions.pausePeriod(row["id"]) : actions.initializePeriod(row["id"])}>
                          {row["ativo"] ? "Pausar Período" : "Iniciar Período"}
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage="Linhas por página:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : to}`}
          backIconButtonText="Página anterior"
          nextIconButtonText="Próxima página"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

class AdminWhitespacePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: ''
    };
  }

  componentWillMount() {
    this.props.actions.requestPeriods();
  }

  handleSearchText = (event) => {
    const { value } = event.target;

    this.setState({
      searchText: value,
    });
  };

  render() {
    var rows = [];

    if (!this.props.adminStocks.isLoading && this.props.adminStocks) {
      rows = this.props.adminStocks.periods;
    }

    if (this.state.searchText !== "") {
      rows = rows.filter((k) =>
        k?.nome?.toLowerCase().includes(this.state.searchText.toLowerCase())
      );
    }

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />

        <SimpleBoxError
          open={this.props.adminStocks.stockError}
          onClose={this.props.actions.closeError}
          errorTitle={this.props.adminStocks.stockMessage}
          errorMessage={""}
        />

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.adminStocks.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <ArrowBackIcon color="transparent" />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="text.primary">Período</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <StockTable rows={rows} actions={this.props.actions} handleSearchText={this.handleSearchText} />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminUserActions,
        ...adminStockActions,
        ...userActions,
      }, dispatch),
    };
  }
)(AdminWhitespacePage);