import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import { loadUser, reducer as oidcReducer } from "redux-oidc";
import userManager from "../util/userManager";
import { connectRouter } from "connected-react-router";
import * as Simulators from "../actions/simulator.actions";
import * as Products from "../actions/products.actions";
import * as Enrichment from "../actions/enrichment.actions";
import * as Clients from "../actions/client.actions";
import * as Campaigns from "../actions/campaign.actions";
import * as PayConditions from "../actions/paycondition.action";
import * as Packs from "../actions/pack.action";
import * as DiscountPacks from "../actions/packdiscount.action";
import * as ProdutoPacks from "../actions/produtopack.action";
import * as Produtores from "../actions/produtores.actions";
import * as DateEncargos from "../actions/dateencargos.action";
import * as DescontoCategoriaSimulador from "../actions/categorydiscount.action";
import * as productPriceActions from "../actions/productprice.actions";
import * as encargosRate from "../actions/encargosrate.actions";
import * as GetAllDiscountCategoryActions from "../actions/getalldiscountcategory.action"
import * as Encargos from "../actions/encargos.action"
import * as ListCenarios from "../actions/cenario.actions"
import * as Cenarios from "../actions/savecenario.actions"
import * as CenariosItems from "../actions/savecenarioitems.actions"
import * as AllCampaignsActions from "../actions/allcampaign.actions"
import * as DeleteCenarioActions from "../actions/deletecenario.action"
import * as AnalyticsProducts from "../actions/analyticsproducts.actions"
import * as SaveGenerateCenario from "../actions/analyticsproducts.actions"
import * as GetCenario from "../actions/getcenario.actions"
import * as User from "../actions/user.actions"
import * as Formulario from "../actions/formulario.actions"
import * as Whitespace from "../actions/whitespace.actions.js";
import * as CulturasWhitespace from "../actions/culturasWhitespace.actions";
import * as AdminWhitespace from "../actions/admin/whitespace.actions.js";
import * as NivelSemaforo from "../actions/nivelSemaforo.actions.js";
import * as AdminUser from "../actions/admin/user.actions";
import * as AdminModulo from "../actions/admin/modulo.actions";
import * as AdminRole from "../actions/admin/role.actions";
import * as AdminTerritory from "../actions/admin/territory.actions";
import * as AdminAccountWhitespace from "../actions/admin/accountWhitespace.actions";
import * as Stocks from "../actions/stock.actions";
import * as AdminStocks from "../actions/admin/stock.actions";
import * as Alert from "../actions/alert.actions.js"
import * as Simulator2Reducer from "../_reducers/simulator2.reducer.js";
import * as SimulatorSeeds from "../actions/simulatorSeeds.actions.js";
import * as ClientesVendaExterna from "../actions/clientTerritory.actions";
import * as ProdutosVendaExterna from "../actions/produtove.action";
import * as PrecoVendaExterna from "../actions/precove.actions";
import * as EncargoVendaExterna from "../actions/simuladorVeEncargos.action";
import * as SimuladorVendaExterna from "../actions/simuladorVendaExterna.actions";
import * as MetodoPagamento from "../actions/metodopagamento.actions.js";
import * as Workflow from "../actions/workflow.actions.js";
import * as SeedsEmbalagens from "../actions/simuladorSeedsEmbalagens.action.js";
import * as valorAreaLider from "../actions/valorAreaLider.actions.js";
import * as cpa from "../actions/cpa.actions.js";
import * as cpaEstrutura from "../actions/cpaEstruturaComercial.actions.js";
import * as ordensDesmembramento from "../actions/simuladordesmemberment.actions.js";
import * as desmembramento from "../actions/desmembramento.actions.js";
import * as AdminSimuladorVe from "../actions/admin/simuladorVe.actions.js";
import * as AdminCampaignVe from "../actions/admin/campaignVe.actions.js";
import * as CampaignVe from "../actions/campaignVe.actions.js";
import * as Simulador2Combo from "../actions/simulator2Combo.actions.js";

export default function configureStore(history, initialState) {
  const reducers = {
    oidc: oidcReducer,
    simulators: Simulators.reducer,
    produtos: Products.reducer,
    enrichment: Enrichment.reducer,
    clientes: Clients.reducer,
    campanhas: Campaigns.reducer,
    condicaoPagamentos: PayConditions.reducer,
    packs: Packs.reducer,
    packdiscounts: DiscountPacks.reducer,
    produtoPacks: ProdutoPacks.reducer,
    produtores: Produtores.reducer,
    dateencargos: DateEncargos.reducer,
    descontoCategoriaSimulador: DescontoCategoriaSimulador.reducer,
    productPrice: productPriceActions.reducer,
    encargosRate: encargosRate.reducer,
    getAllDiscountCategoryActions: GetAllDiscountCategoryActions.reducer,
    encargos: Encargos.reducer,
    cenarios: Cenarios.reducer,
    cenariosItems: CenariosItems.reducer,
    allcampanhas: AllCampaignsActions.reducer,
    deleteCenario: DeleteCenarioActions.reducer,
    analyticsProdutos: AnalyticsProducts.reducer,
    seedsEmbalagens: SeedsEmbalagens.reducer,
    saveGenerateCenario: SaveGenerateCenario.reducer,
    listCenarios: ListCenarios.reducer,
    getCenario: GetCenario.reducer,
    user: User.reducer,
    whitespace: Whitespace.reducer,
    formulario: Formulario.reducer,
    culturasWhitespace: CulturasWhitespace.reducer,
    stocks: Stocks.reducer,
    alert: Alert.reducer,
    accountWhitespace: AdminAccountWhitespace.reducer,
    simulator2: Simulator2Reducer.reducer,
    simuladorSeeds: SimulatorSeeds.reducer,
    clientesVendaExterna: ClientesVendaExterna.reducer,
    produtosVendaExterna: ProdutosVendaExterna.reducer,
    precoVendaExterna: PrecoVendaExterna.reducer,
    encargoVendaExterna: EncargoVendaExterna.reducer,
    simuladorVendaExterna: SimuladorVendaExterna.reducer,
    metodoPagamento: MetodoPagamento.reducer,
    workflow: Workflow.reducer,
    valorAreaLider: valorAreaLider.reducer,
    cpa: cpa.reducer,
    cpaEstrutura: cpaEstrutura.reducer,
    ordensDesmembramento: ordensDesmembramento.reducer,
    dmb: desmembramento.reducer,
    campaignVe: CampaignVe.reducer,
    simulador2Combo: Simulador2Combo.reducer,

    //Admin Reducers
    adminWhitespace: AdminWhitespace.reducer,
    adminUser: AdminUser.reducer,
    adminModulo: AdminModulo.reducer,
    adminRole: AdminRole.reducer,
    adminTerritory: AdminTerritory.reducer,
    adminStocks: AdminStocks.reducer,
    nivelSemaforo: NivelSemaforo.reducer,
    adminSimuladorVe: AdminSimuladorVe.reducer,
    adminCampaignVe: AdminCampaignVe.reducer
  };

  const isDevelopment = process.env.NODE_ENV === "development";

  // Middleware configuration
  const middleware = [thunk, routerMiddleware(history)];

  // Enhancers configuration
  const devToolsExtension = 
    isDevelopment && 
    typeof window !== "undefined" && 
    window.__REDUX_DEVTOOLS_EXTENSION__ ? 
      window.__REDUX_DEVTOOLS_EXTENSION__() : 
      f => f;

  const enhancers = [devToolsExtension];

  // Root reducer configuration
  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  // Store creation
  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );

  // Load user state for user manager
  if (userManager) {
    loadUser(store, userManager);
  }

  return store;
}
