/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */

import dayjs from "dayjs";

const addRow = "SIMULADOR_VE_ADD_ROW";
const updateAll = "SIMULADOR_VE_UPDATE_ALL";
const deleteRow = "SIMULADOR_VE_DELETE_ROW";
const requestSaveCenario = "REQUEST_SAVE_CENARIO";
const receiveSaveCenario = "RECEIVE_SAVE_CENARIO";
const removeAllRows = "REMOVE_ALL_ROW";
const requestSimulacaoCliente = "REQUEST_SIMULACAO_CLIENTE";
const receiveSimulacaoCliente = "RECEIVE_SIMULACAO_CLIENTE";
const requestDeleteCenario = "REQUEST_DELETE_CENARIO";
const receiveDeleteCenario = "RECEIVE_DELETE_CENARIO";
const requestLoadCenario = "REQUEST_LOAD_CENARIO";
const receiveLoadCenario = "RECEIVE_LOAD_CENARIO";
const requestUpdateCenario = "REQUEST_UPDATE_CENARIO";
const receiveUpdateCenario = "RECEIVE_UPDATE_CENARIO";
const updateCenarioFailure = "UPDATE_CENARIO_FAILURE";
const clearSimuladorVeData = "CLEAR_SIMULADOR_VE_DATA";

const initialState = {
  itens: [],
  isLoading: false,
  cenario: undefined,
  rows: [],
};

const CreateRow = (
  ordem,
  produto,
  preco,
  encargos,
  moeda,
  prazo,
  dataEntrega,
  quantidade,
  precoNegociado,
  valorNegociado
) => {
  const encargosProduto = encargos.find(
    (x) => moeda === x.moeda && prazo === x.condicaoComercial
  );
  const encargoProduto = encargosProduto.encargo / 100;
  const precoMinimo = preco.precoMinimo * (1 + encargoProduto);
  const precoSugerido = preco.precoSugerido * (1 + encargoProduto);

  let valor = 0;
  if (quantidade === 0) {
    valor = precoSugerido * 1;
    valorNegociado = precoNegociado * 1;
  } else {
    valor = precoSugerido * quantidade;
    valorNegociado = precoNegociado * quantidade;
  }

  return {
    ordem: ordem,
    produtoId: produto.id,
    produto: produto,
    multiploVolume: produto.multiploVolume,
    precoMinimo: precoMinimo,
    precoSugerido: precoSugerido,
    encargo: encargoProduto,
    volume: quantidade,
    valorTotal: valor,
    moeda: moeda,
    dataEntrega: dayjs(dataEntrega),
    dataDisponibilidade: dayjs(preco.dataDisponibilidade),
    prazoEntrega: preco.prazoEntrega,
    prazoPagamento: prazo,
    precoNegociado: precoNegociado ?? 0,
    valorFinalNegociado: valorNegociado,
  };
};

const LoadRow = (
  ordem,
  produto,
  preco,
  encargos,
  moeda,
  prazo,
  dataEntrega,
  quantidade,
  precoNegociado,
  valorNegociado
) => {
  const encargosProduto = encargos.find(
    (x) => moeda === x.moeda && prazo === x.condicaoComercial
  );
  const encargoProduto = encargosProduto.encargo / 100;
  const precoMinimo = preco.precoMinimo;
  const precoSugerido = preco.precoSugerido;

  let valor = 0;
  if (quantidade === 0) {
    valor = precoSugerido;
    valorNegociado = precoNegociado;
  } else {
    valor = precoSugerido * quantidade;
    valorNegociado = precoNegociado * quantidade;
  }

  return {
    ordem: ordem,
    produtoId: produto.id,
    produto: produto,
    multiploVolume: produto.multiploVolume,
    precoMinimo: precoMinimo,
    precoSugerido: precoSugerido,
    encargo: encargoProduto,
    volume: quantidade,
    valorTotal: valor,
    moeda: moeda,
    dataEntrega: dayjs(dataEntrega),
    dataDisponibilidade: dayjs(preco.dataDisponibilidade),
    prazoEntrega: preco.prazoEntrega,
    prazoPagamento: prazo,
    precoNegociado: precoNegociado ?? 0,
    valorFinalNegociado: valorNegociado,
  };
};

export const simuladorVeActions = {
  addRow:
    (ordem, produto, precos, encargos, moeda, prazo, dataDisponibilidade) => async (dispatch) => {
      if (produto) {
        const row = CreateRow(
          ordem,
          produto,
          precos,
          encargos,
          moeda,
          prazo,
          undefined,
          0,
          0,
          0
        );
        dispatch({ type: addRow, row: row });
      }
    },
  deleteRow: (produto) => async (dispatch) => {
    dispatch({
      type: deleteRow,
      produto: produto,
    });
  },
  deleteCenario: (cenarioId) => async (dispatch, getState) => {
    const { oidc } = getState();
    dispatch({ type: requestDeleteCenario });

    const url = `api/simuladorVendaExterna/deleteCenario/${cenarioId}`;
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Erro desconhecido");
      }
      dispatch({ type: receiveDeleteCenario, cenarioId });
    } catch (error) {
      console.error("Failed to delete scenario", error);
    }
  },

  updateRows: (rows) => async (dispatch) => {
    dispatch({ type: updateAll, rows });
  },
  removeAllRows: () => async (dispatch) => {
    dispatch({ type: removeAllRows });
  },

  createSaveGenerateCenario:
    (
      cliente,
      status,
      rows,
      total,
      campanha,
      moeda,
      prazoPagamento,
      barter,
      observacao
    ) =>
    async (dispatch, getState) => {
      const { oidc } = getState();

      const cenario = {
        clienteId: cliente,
        status: status,
        total: total,
        campanhaId: campanha,
        moeda: moeda,
        condicaoComercial: prazoPagamento,
        observacao: observacao,
        barter: barter,
        simuladorVendaExternaItens: rows,
      };

      dispatch({ type: requestSaveCenario });

      const url = `api/simuladorVendaExterna/saveItens`;
      const options = {
        method: "POST",
        body: JSON.stringify(cenario),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Erro desconhecido");
        }
        const SaveCenarioItems = await response.json();
        dispatch({ type: receiveSaveCenario, SaveCenarioItems });
      } catch (error) {
        console.error("Erro ao salvar cenário:", error);
      }
    },

  simulacaoCliente: (clienteId) => async (dispatch, getState) => {
    dispatch({ type: requestSimulacaoCliente });

    const { oidc } = getState();
    const url = `api/simuladorVendaExterna/simulacaoCliente/${clienteId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const simulacoes = await response.json();

      dispatch({ type: receiveSimulacaoCliente, simulacoes: simulacoes });
    } catch (error) {
      console.error("Failed to fetch simulation data", error);
      dispatch({ type: receiveSimulacaoCliente, simulacoes: [] });
    }
  },
  loadCenario: (cenarioId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestLoadCenario });

    const url = `api/simuladorVendaExterna/cenario/${cenarioId}`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Erro desconhecido");
      }

      const cenario = await response.json();
      const rows = cenario.simuladorVendaExternaItens.map((item) => {
        const produto = item.produto;
        const precos = {
          produtoId: item.produtoId,
          precoMinimo: item.precoMinimo,
          precoSugerido: item.precoSugerido,
        };
        const encargos = [
          {
            moeda: item.moeda,
            condicaoComercial: item.prazoPagamento,
            encargo: item.encargo * 100,
          },
        ];
        const volume = item.volume;
        const precoNegociado = item.precoNegociado;
        const valorFinalNegociado = item.valorFinalNegociado;

        return LoadRow(
          item.ordem,
          produto,
          precos,
          encargos,
          item.moeda,
          item.prazoPagamento,
          item.dataEntrega,
          volume,
          precoNegociado,
          valorFinalNegociado
        );
      });

      dispatch({ type: receiveLoadCenario, rows, cenario });
    } catch (error) {
      console.error("Erro ao carregar cenário:", error);
    }
  },
  updateCenario:
    (
      cenarioId,
      status,
      total,
      campanha,
      moeda,
      condicaoPagamento,
      rows,
      barter,
      observacao
    ) =>
    async (dispatch, getState) => {
      const { oidc } = getState();

      dispatch({ type: requestUpdateCenario });

      const cenario = {
        status: status,
        total: total,
        campanhaId: campanha,
        moeda: moeda,
        condicaoComercial: condicaoPagamento,
        observacao: observacao,
        barter: barter,
        simuladorVendaExternaItens: rows,
      };

      const url = `api/simuladorVendaExterna/atualizarCenario/${cenarioId}`;
      const options = {
        method: "PUT",
        body: JSON.stringify(cenario),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Erro desconhecido");
        }
        const updatedData = await response.json();
        dispatch({ type: receiveUpdateCenario, updatedData });
      } catch (error) {
        console.error("Erro ao atualizar cenário:", error);
        dispatch({ type: updateCenarioFailure, error });
      }
    },

  clearSimuladorVeData: () => async (dispatch) => {
    dispatch({ type: clearSimuladorVeData });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === addRow) {
    const rows = state.rows;

    if (rows.some((item) => item.produtoId == action.row.produtoId))
      return { ...state, isLoading: false };

    return {
      ...state,
      isLoading: false,
      rows: [...rows, action.row],
    };
  }

  if (action.type === deleteRow) {
    return {
      ...state,
      rows: state.rows
        .filter((row) => row.produtoId !== action.produto)
        .map((row, index) => ({ ...row, ordem: index + 1 })),
      isLoading: false,
    };
  }

  if (action.type === updateAll) {
    return {
      ...state,
      rows: action.rows,
      isLoading: false,
    };
  }

  if (action.type === receiveSaveCenario) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === removeAllRows) {
    return {
      ...state,
      rows: state.rows.splice(),
      isLoading: false,
    };
  }
  if (action.type === requestSimulacaoCliente) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveSimulacaoCliente) {
    return {
      ...state,
      itens: action.simulacoes,
      isLoading: false,
    };
  }
  if (action.type === requestDeleteCenario) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveDeleteCenario) {
    return {
      ...state,
      itens: state.itens.filter((item) => item.id !== action.cenarioId),
      isLoading: false,
    };
  }
  if (action.type === requestLoadCenario) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveLoadCenario) {
    return {
      ...state,
      rows: action.rows,
      cenario: action.cenario,
      isLoading: false,
    };
  }

  if (action.type === requestUpdateCenario) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveUpdateCenario) {
    return {
      ...state,
      itens: state.itens.map((item) =>
        item.id === action.updatedData.id ? action.updatedData : item
      ),
      isLoading: false,
    };
  }

  if (action.type === updateCenarioFailure) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }

  if (action.type === clearSimuladorVeData) {
    return {
      ...state,
      isLoading: false,
      cenario: undefined,
      itens: [],
      rows: [],
    };
  }

  return state;
};
