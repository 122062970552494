import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { StocksActions } from "../../../actions/stock.actions";
import { whitespaceActions } from "../../../actions/whitespace.actions";
import { clientActions } from "../../../actions/client.actions";
import { userActions } from "../../../actions/user.actions";
import ChangeStock from "./ChangeStockMenu";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TableSortLabel from "@mui/material/TableSortLabel";
import { NumericFormat } from "react-number-format";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import OutboxIcon from "@mui/icons-material/Outbox";
import InventoryIcon from "@mui/icons-material/Inventory";
import EditIcon from '@mui/icons-material/Edit';
import FilterDialog from "./FilterDialog";
import ResumeDialog from "./ResumeTable"
import AddStockDialog from "./AddStockDialog";
import ButtonSelectTerrritory from "../../ButtonSelectTerrritory";
import InformationDrawer from "./InformationDrawer";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const headCells = [
  {
    id: "canal",
    align: "center",
    label: "Canal",
    editable: false,
    sortable: true,
    searchble: true,
    style: {
      position: "sticky",
      left: 0,
      minWidth: 130,
      maxWidth: 130,
      backgroundColor: "white",
    },
    zIndex: 101,
  },
  {
    id: "produto",
    align: "center",
    label: "Produto",
    editable: false,
    sortable: true,
    searchble: true,
    style: {
      position: "sticky",
      left: 130,
      minWidth: 130,
      maxWidth: 130,
      backgroundColor: "white",
      borderRight: "1px solid #747c7f !important",
    },
    zIndex: 100,
  },
  {
    id: "segmento",
    align: "center",
    label: "Segmento",
    editable: false,
    sortable: true,
    searchble: true,
    zIndex: 99,
    style: {
      whiteSpace: "nowrap",
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "raizCNPJ",
    align: "center",
    label: "Raiz de CNPJ",
    editable: false,
    sortable: true,
    searchble: true,
    zIndex: 98,
    style: {
      whiteSpace: "nowrap",
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "cnpj",
    align: "center",
    label: "CNPJ",
    format: "CNPJ",
    editable: false,
    sortable: true,
    searchble: true,
    hiddenInRaiz: true,
    zIndex: 98,
    style: {
      whiteSpace: "nowrap",
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "municipio",
    align: "center",
    label: "Município",
    editable: false,
    sortable: true,
    searchble: true,
    hiddenInRaiz: true,
    zIndex: 97,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "uf",
    align: "center",
    label: "UF",
    editable: false,
    sortable: true,
    searchble: true,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueFisicoAnterior",
    align: "center",
    label: "Estoque Físico Anterior",
    editable: false,
    sortable: true,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueDisponivelAnterior",
    align: "center",
    label: "Estoque Disponível Anterior",
    editable: false,
    sortable: true,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  // {
  //   id: "sellInCorteva",
  //   align: "center",
  //   label: "Sell In Corteva",
  //   //secondaryLabel: "(Kg | L | sc)",
  //   editable: false,
  //   sortable: true,
  //   style: {
  //     minWidth: "fit-content",
  //     maxWidth: "160px",
  //   }
  // },
  // {
  //   id: "sellInIntegra",
  //   align: "center",
  //   label: "Sell In Integra",
  //   //secondaryLabel: "(Kg | L | sc)",
  //   editable: false,
  //   sortable: true,
  //   style: {
  //     minWidth: "fit-content",
  //     maxWidth: "160px",
  //   }
  // },
  // {
  //   id: "sellOutIntegra",
  //   align: "center",
  //   label: "Sell Out Integra",
  //   //secondaryLabel: "(Kg | L | sc)",
  //   editable: false,
  //   sortable: true,
  //   style: {
  //     minWidth: "fit-content",
  //     maxWidth: "160px",
  //   }
  // },
  // {
  //   id: "saldoEstoque",
  //   align: "center",
  //   label: "Saldo Estoque",
  //   //secondaryLabel: "(Kg | L | sc)",
  //   editable: false,
  //   sortable: true,
  //   style: {
  //     minWidth: "fit-content",
  //     maxWidth: "160px",
  //   }
  // },
  {
    id: "estoqueFisicoIntegra",
    align: "center",
    label: "Estoque Físico Integra",
    //secondaryLabel: "(Kg | L | sc)",
    editable: false,
    sortable: true,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueDisponivelIntegra",
    align: "center",
    label: "Estoque Disponivel Integra",
    //secondaryLabel: "(Kg | L | sc)",
    editable: false,
    sortable: true,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueFisico",
    align: "center",
    label: "Estoque Físico",
    //secondaryLabel: "(Kg | L | sc)",
    editable: true,
    sortable: false,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueDisponivel",
    align: "center",
    label: "Estoque Disponível",
    //secondaryLabel: "(Kg | L | sc)",
    editable: true,
    sortable: false,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueValido",
    align: "center",
    label: "",
    action: true,
    editable: false,
    sortable: false,
    style: {
      position: "sticky",
      left: 0,
      minWidth: 75,
      maxWidth: 75,
    },
    zIndex: 100,
  },
];

function formatToCNPJ(srt) {
  return srt?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5") ?? "";
}

function formatNumber(num) {
  return num?.toLocaleString('pt-BR', { maximumFractionDigits: 0 }) ?? "";
}

function removeAccents(str) {
  return str?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")?.replace(/\s+/g, "")?.toLowerCase() ?? "";
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { period, role, order, orderBy, handleSearch, onRequestSort, actions, rows, unfilteredRows } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        th: {
          color: "#FFF",
          fontWeight: "bold",
          backgroundColor: "var(--cort-blue) !important",
        },
      }}
    >
      <TableRow>
        {headCells.filter(x => period?.porRaiz ? !x.hiddenInRaiz : true).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            size="medium"
            sx={{
              "*": {
                color: "#FFF !important",
                "&:hover": {
                  color: "#FFF",
                },
              },
              minWidth: "160px",
              zIndex: headCell.zIndex,
              ...headCell.style,
            }}
          >
            {headCell.action &&
              <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                <ChangeStock
                  sort={createSortHandler(headCell.id)}
                  actions={actions}
                  role={role}
                  rowsIds={rows.map(x => x.id)}
                />
                {orderBy === headCell.id ? order === "asc" ?
                  <ArrowUpwardIcon sx={{ fontSize: "1rem" }} />
                  :
                  <ArrowDownwardIcon sx={{ fontSize: "1rem" }} />
                  :
                  ""
                }
              </Stack>
            }
            {!headCell.action && headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                hideSortIcon={orderBy !== headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ margin: "0px !important" }}>
                    {headCell.label}
                  </div>
                  <div style={{ margin: "0px !important" }}>
                    {headCell.secondaryLabel}
                  </div>
                </div>
                {headCell.searchble && (
                  <FilterDialog
                    applyFilter={handleSearch}
                    rows={rows}
                    unfilteredRows={unfilteredRows}
                    label={headCell.label}
                  />
                )}
              </TableSortLabel>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ margin: "0px !important" }}>{headCell.label}</div>
                <div style={{ margin: "0px !important" }}>
                  {headCell.secondaryLabel}
                </div>
                {headCell.searchble && (
                  <FilterDialog
                    applyFilter={handleSearch}
                    rows={rows}
                    unfilteredRows={unfilteredRows}
                    label={headCell.label}
                  />
                )}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({
  rows,
  channels,
  products,
  municipios,
  msgSave,
  actions,
  stockPeriod,
  role
}) {
  const actualPeriod = stockPeriod.find((x) => x.ativo);
  const isAdmin = role === 'Admin';

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 30;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePeriodChange = (_, period) => {
    if (period.id) {
      actions.setStockPeriod(period.id);
      setAnchorEl(null);
    }
  };

  return (
    <Grid
      container
      columnSpacing={2}
      sx={{
        margin: "0px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Grid item xs={4.5} sx={{ paddingLeft: "0px !important" }}>
        <Stack direction={"column"}>
          <Stack direction={"row"}>
            <h4 style={{ color: "var(--cort-blue)", fontWeight: "bold", alignContent: "center", margin: "0" }}>
              Gerenciar Estoque - {(actualPeriod?.ano &&
                `${actualPeriod?.ano} - Mês ${actualPeriod?.trimestre}`) ||
                ""}
            </h4>
            {isAdmin && (
              <Stack direction="row"
                spacing={2}
                sx={{
                  width: "40px",
                  height: "38px",
                  marginLeft: "10px",
                  alignItems: "center"
                }}>
                <Tooltip title="Alterar Período" placement="top" >
                  <IconButton sx={{
                    color: "var(--cort-blue)",
                    '&:hover': { color: "#fff", backgroundColor: "var(--cort-blue)" },
                    borderRadius: "6px",
                    marginLeft: "10px",
                  }}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <SwapHorizIcon />
                  </IconButton >
                </Tooltip>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                    },
                  }}
                >
                  {stockPeriod.map((option) => (
                    <MenuItem key={option} onClick={(event) => handlePeriodChange(event, option)}>
                      {option.trimestre} - {option.ano}
                    </MenuItem>
                  ))}
                </Menu>
              </Stack>
            )}
          </Stack>
          <h6 style={{ color: "#000", fontStyle: "italic", fontWeight: "300" }}>
            Unidades de medida ( Kg | L | sc )
          </h6>
        </Stack>

      </Grid>
      <Grid item xs={4.5} sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ flex: "1 1 40%", textAlign: "end" }} component="div">
          {msgSave}
        </Typography>
        <Tooltip title="Atualizar dados">
          <IconButton onClick={() => actions.requestAllData()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      {/* <Grid item xs={2}>
        <CreateChannel
          products={products}
          municipios={municipios}
          confirmAction={actions.handleCreateChannel}
        />
      </Grid> */}
      <Grid item xs={2}>
        <InformationDrawer />
      </Grid>
      <Grid item xs={0.5}>
        <AddStockDialog
          period={actualPeriod}
          channels={channels}
          products={products}
          confirmAction={actions.handleCreateStock}
        />
      </Grid>
      <Grid item xs={0.5}>
        <ResumeDialog
          rows={rows} />
      </Grid>
    </Grid>
  );
}

function StockTable(props) {
  const {
    role,
    rows,
    actions,
    stockProps,
    municipios,
    msgSave,
    handleSearch,
    stockPeriod,
  } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("nome");

  const handleApplyFilter = (value, column) => {
    handleSearch(value, column);
    setPage(0);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <EnhancedTableToolbar
        rows={rows}
        channels={stockProps.channels}
        products={stockProps.products}
        municipios={municipios}
        msgSave={msgSave ? msgSave : "Sem Alterações"}
        actions={actions}
        stockPeriod={stockPeriod}
        role={role}
      />
      <TableContainer
        component={Paper}
        sx={{
          minWidth: 650,
          maxHeight: "calc(56vh - 16px)",
          'th': {
            fontSize: "0.7rem",
            padding: "4px 8px"
          },
          'td': {
            minWidth: "fit-content",
            maxWidth: "160px",
            fontSize: "0.7rem",
            padding: "4px"
          },
          'tr:nth-of-type(odd) td': {
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        <Table stickyHeader size="small">
          <EnhancedTableHead
            period={stockPeriod.find(x => x.ativo)}
            role={role}
            rows={rows}
            unfilteredRows={stockProps.stocks}
            actions={actions}
            order={order}
            orderBy={orderBy}
            handleSearch={handleApplyFilter}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {(rows != null && rowsPerPage > 0
              ? stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : stableSort(rows, getComparator(order, orderBy))
            ).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {headCells.filter(x => stockPeriod.find(x => x.ativo)?.porRaiz ? !x.hiddenInRaiz : true).map((column) => {
                    const value = row[column.id];
                    const isInput = column.editable;
                    const isAction = column.action;
                    const isBoolean = value === true || value === false;

                    return (
                      <TableCell
                        key={`${column.id}${row.id}`}
                        align={column.align}
                        sx={{
                          ...column.style,
                          borderBottom: "1px solid #747c7f !important",
                        }}
                      >
                        {isBoolean && (value ? "Sim" : "Não")}

                        {isAction && (
                          <ActionButton role={role} row={row} handleInterectStock={props.actions.handleConfirmStock} />
                        )}
                        {isInput && (
                          <NumericFormat
                            disabled={role?.includes("DSM") || role?.includes("KAM") || role?.includes("Admin") ? row.status === 2 : row.status >= 1}
                            customInput={TextField}
                            name={column.id}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={1}
                            size="small"
                            value={
                              (column.id === "estoqueFisico" ? row.apontadoManualmenteFisico
                                : column.id === "estoqueDisponivel" ? row.apontadoManualmenteDisponivel : true)
                                || row.status >= 1 || value > 0 ? value : ""
                            }
                            onClick={(e) =>
                              props.actions.handleClickNumeric(
                                e,
                                row.id
                              )
                            }
                            onValueChange={(e) => props.actions.handleChangeNumeric(
                              e.value,
                              column.id,
                              row.id
                            )}
                            onBlur={(e) => {
                              props.actions.handleBlurNumeric(e, row);
                            }}
                            sx={{
                              minWidth: "90px",
                              "div input": {
                                padding: "8px !important",
                              },
                            }}
                            className="numericInput"
                          />
                        )}
                        {!isInput && !isAction && (column.format === "CNPJ" ? formatToCNPJ(value) : formatNumber(value))}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : to}`
        }
        backIconButtonText="Página anterior"
        nextIconButtonText="Próxima página"
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rows?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          borderRadius: "0 0 8px 8px",
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        }}
      />
    </div>
  );
}

function ActionButton(props) {
  const { role, row, handleInterectStock } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip
        title={
          row.status === 0
            ? "Não Validado"
            : row.status === 1 ? "Aguardando Aprovação" : "Aprovado"
        }
      >
        <IconButton
          aria-label={
            row.status === 0
              ? "Não Validado"
              : row.status === 1 ? "Aguardando Aprovação" : "Aprovado"
          }
          color={row.status === 0 ? "vermelho" : row.status === 1 ? "azul" : "verde"}
          onClick={(e) => {
            if (role?.includes("Sales Representative")) {
              if (row.status === 0) {
                handleInterectStock(row.id, 1);
              } else if (row.status === 1) {
                handleInterectStock(row.id, 0);
              }
            } else if (role?.includes("DSM")) {
              if (row.status !== 2) {
                handleInterectStock(row.id, 2);
              } else {
                handleInterectStock(row.id, 1);
              }
            }
            else if (role?.includes("Admin") || role?.includes("KAM")) {
              if (row.status !== 2) {
                handleInterectStock(row.id, 2);
              } else {
                handleInterectStock(row.id, 0);
              }
            } else {
              handleClick(e);
            }
          }}
        >
          {row.status > 0 ? <InventoryIcon /> : <OutboxIcon />}
        </IconButton>
      </Tooltip>
      {
        role?.includes("Admin") && (
          <Menu
            id="stock-validate-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'stock-validate-btn',
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => { handleClose(); handleInterectStock(row.id, 0) }}>
              Apontar Novamente
            </MenuItem>
            {row.status === 0 && (
              <MenuItem onClick={() => { handleClose(); handleInterectStock(row.id, 1) }}>
                Validar
              </MenuItem>
            )}
            {row.status === 1 && (
              <MenuItem onClick={() => { handleClose(); handleInterectStock(row.id, 2) }}>
                Aprovar
              </MenuItem>
            )}
          </Menu>
        )
      }
    </div >
  )
}

class StockPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchRaizCnpj: [],
      searchCnpj: [],
      searchChannel: [],
      searchMunicipio: [],
      searchProduct: [],
      searchUf: [],
      searchSegmento: [],

      territorio: undefined,
      area: undefined,
      distrito: undefined,
      isSetStorage: false
    };

    this.onChangeTerritorio = this.onChangeTerritorio.bind(this);
    this.setByStorage = this.setByStorage.bind(this);
    this.onChangeArea = this.onChangeArea.bind(this);
    this.onChangeDistrito = this.onChangeDistrito.bind(this);
    this.onChangeTerritorio = this.onChangeTerritorio.bind(this);
  }

  componentWillMount() {
    //Filter Territory Requests
    this.props.actions.requestTerritorios();
    this.props.actions.requestRole();
    this.props.actions.requestRoleLevels();

    //Stocks Table Requests
    this.props.actions.requestUser();
    this.props.actions.requestAllData();
    this.props.actions.requestTerritoryIbge();
    this.props.actions.requestStockPeriods();
    this.props.actions.getAllMunicipios();
  }

  handleSearch = (value, column) => {
    switch (removeAccents(column)) {
      case "raizdecnpj":
        this.setState({ searchRaizCnpj: value });
        break;
      case "cnpj":
        this.setState({ searchCnpj: value });
        break;
      case "canal":
        this.setState({ searchChannel: value });
        break;
      case "municipio":
        this.setState({ searchMunicipio: value });
        break;
      case "produto":
        this.setState({ searchProduct: value });
        break;
      case "uf":
        this.setState({ searchUf: value });
        break;
      case "segmento":
        this.setState({ searchSegmento: value });
        break;
      default:
        break;
    }
  };

  onChangeArea(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ area: newInputValue });
      this.setState({ distrito: undefined });
      this.setState({ territorio: undefined });
      localStorage.setItem("Area", JSON.stringify(newInputValue));
      localStorage.removeItem("Distrito");
      localStorage.removeItem("Territorio");
    }
    else {
      this.setState({ area: options[0] });
      localStorage.setItem("Area", JSON.stringify(options[0]));
    }
  }

  onChangeDistrito(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ distrito: newInputValue });
      this.setState({ territorio: undefined });
      localStorage.setItem("Distrito", JSON.stringify(newInputValue));
      localStorage.removeItem("Territorio");

    }
    else {
      this.setState({ distrito: options[0] });
      localStorage.setItem("Distrito", JSON.stringify(options[0]));
    }

  }

  onChangeTerritorio(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ territorio: newInputValue });
      localStorage.setItem("Territorio", JSON.stringify(newInputValue));
      this.props.actions.changeTerritorio(newInputValue.territoryId);
    }
    else {
      this.setState({ territorio: options[0] });
      localStorage.setItem("Territorio", JSON.stringify(options[0]));
      this.props.actions.changeTerritorio(options[0].territoryId);
    }

  }

  setByStorage(areas = [], distritos = [], territorios = []) {
    var area = JSON.parse(localStorage.getItem("Area"))
    var distrito = JSON.parse(localStorage.getItem("Distrito"))
    var territorio = JSON.parse(localStorage.getItem("Territorio"))
    if (area && areas.find(x => x === area) !== undefined) {
      this.setState({ area: area });
    } else {
      localStorage.removeItem("Area")
    }
    if (distrito && distritos.find(x => x.districtName === distrito.districtName) !== undefined) {
      this.setState({ distrito: distrito });
    } else {
      localStorage.removeItem("Distrito")
    }
    if (territorio && territorios.find(x => x.territoryName === territorio.territoryName) !== undefined) {
      this.setState({ territorio: territorio });
    } else {
      localStorage.removeItem("Territorio")
    }

    this.setState({ isSetStorage: true });
  }

  render() {
    var rows = [];

    var territorio = "";
    var role = "";
    var roleLevels = null;
    var territorios = [{ territoryName: "TODOS" }];
    var areas = ["TODOS"];
    var distritos = [{ districtName: "TODOS" }];

    if (this.props.stocks.stocks.length > 0) {
      let stocks = this.props.stocks.stocks;

      rows = stocks?.map((x) => {
        return {
          id: x.id,
          status: x.estoqueValido,
          codIbge: x.estoqueCanal.codIbge,
          cnpj: x.estoqueCanal.cnpj,
          produto: x.estoqueProduto.nome,
          produtoLinha: x.estoqueProduto.linha,
          raizCNPJ: x.estoqueCanal.raizCNPJ,
          canal: x.estoqueCanal.canal,
          canalTipo: x.estoqueCanal.tipo,
          municipio: x.estoqueCanal.municipio,
          uf: x.estoqueCanal.uf,
          segmento: x.estoqueProduto.segmento,
          apontadoManualmente: x.apontadoManualmente,
          estoqueValido: x.estoqueValido,
          validacaoAnterior: x.validacaoAnterior,
          estoqueFisicoAnterior: x.estoqueFisicoAnterior,
          estoqueDisponivelAnterior: x.estoqueDisponivelAnterior,
          sellInCorteva: x.sellInCorteva,
          sellInIntegra: x.sellInIntegra,
          sellOutIntegra: x.sellOutIntegra,
          saldoEstoque: x.saldoEstoque,
          estoqueFisicoIntegra: x.estoqueFisicoIntegra,
          estoqueDisponivelIntegra: x.estoqueDisponivelIntegra,
          estoqueFisico: x.estoqueFisico,
          estoqueDisponivel: x.estoqueDisponivel,
          dataCriacao: x.estoqueCanal.dataCriacao,
        };
      });
    }

    if (this.state.searchRaizCnpj.length > 0) {
      rows = rows.filter((k) => {
        const searchRaizCnpjFormatted = removeAccents(
          k.cnpj.toLowerCase()
        );
        return this.state.searchRaizCnpj.some(cnpj =>
          searchRaizCnpjFormatted.includes(removeAccents(cnpj.toLowerCase()))
        );
      });
    }

    if (this.state.searchCnpj.length > 0) {
      rows = rows.filter((k) => {
        const searchCnpjFormatted = removeAccents(
          k.cnpj.toLowerCase()
        );
        return this.state.searchCnpj.some(cnpj =>
          searchCnpjFormatted.includes(removeAccents(cnpj.toLowerCase()))
        );
      });
    }

    if (this.state.searchChannel.length > 0) {
      rows = rows.filter((k) => {
        const channelFormatted = removeAccents(
          k.canal.toLowerCase()
        );
        return this.state.searchChannel.some(canal =>
          channelFormatted === removeAccents(canal.toLowerCase())
        );
      });
    }

    if (this.state.searchMunicipio.length > 0) {
      rows = rows.filter((k) => {
        const municipioFormatted = removeAccents(
          k.municipio.toLowerCase()
        );
        return this.state.searchMunicipio.some(municipio =>
          municipioFormatted.includes(removeAccents(municipio.toLowerCase()))
        );
      });
    }

    if (this.state.searchProduct.length > 0) {
      rows = rows.filter((k) => {
        const searchProductFormatted = removeAccents(
          k.produto.toLowerCase()
        );
        return this.state.searchProduct.some(produto =>
          searchProductFormatted.includes(removeAccents(produto.toLowerCase()))
        );
      });
    }

    if (this.state.searchUf.length > 0) {
      rows = rows.filter((k) => {
        const searchUfFormatted = removeAccents(
          k.uf.toLowerCase()
        );
        return this.state.searchUf.some(uf =>
          searchUfFormatted.includes(removeAccents(uf.toLowerCase()))
        );
      });
    }

    if (this.state.searchSegmento.length > 0) {
      rows = rows.filter((k) => {
        const searchSegmentoFormatted = removeAccents(
          k.segmento.toLowerCase()
        );
        return this.state.searchSegmento.some(segmento =>
          searchSegmentoFormatted.includes(removeAccents(segmento.toLowerCase()))
        );
      });
    }

    if (!this.props.user.isLoadingRole && this.props.user.role != null && !this.props.user.isLoadingRoleLevels && this.props.user.roleLevels != null) {
      roleLevels = this.props.user.roleLevels;
      role = this.props.user.role.role;

      if (role.accessLevel === roleLevels.viewAll)
        territorio = "";
      else if (role.accessLevel >= roleLevels.viewArea)
        territorio = this.props.user.role.areaName;
      else if (role.accessLevel >= roleLevels.viewManyDistrict)
        territorio = this.props.user.role.districtName;
      else if (role.accessLevel >= roleLevels.viewDistrict)
        territorio = this.props.user.role.districtName;
      else if (this.props.user.role.territory != null)
        territorio = this.props.user.role.territory.territoryName;
    }

    if (!this.props.user.isLoadingTerritorios && this.props.user.territorios.length > 0 && !this.props.user.isLoadingRole && this.props.user.role != null && !this.props.user.isLoadingRoleLevels && this.props.user.roleLevels != null) {
      var territoriosIbge = this.props.stocks.territories;
      var territoriosToFilter = this.props.user.territorios
      areas = areas.concat(this.props.user.role.areas);
      areas = areas.filter(x => x !== '');
      var distritosToFilter = this.props.user.role.distritos;

      if (!this.state.isSetStorage) {
        this.setByStorage(this.props.user.role.areas, this.props.user.role.distritos, this.props.user.territorios)
      }

      if (this.state.area !== "" && this.state.area != null && this.state.area !== undefined && role?.accessLevel === roleLevels?.viewAll) {
        if (this.state.area !== "TODOS") {
          distritosToFilter = distritosToFilter.filter(x => x.areaName === this.state.area);
          territoriosToFilter = territoriosToFilter.filter(x => x.areaName === this.state.area)
          rows = rows.filter(x => territoriosToFilter.find(t => x.territory === t.territoryName))
        }
      }

      distritos = distritos.concat(distritosToFilter);
      distritos = distritos.filter(x => x.districtName !== '');
      if (territoriosIbge.length > 0 && this.state.distrito !== "" && this.state.distrito != null && this.state.distrito !== undefined && (role?.accessLevel >= roleLevels?.viewArea || (role?.accessLevel >= roleLevels?.viewManyDistrict && distritos.length > 2))) {
        if (this.state.distrito.districtName !== "TODOS") {
          territoriosToFilter = territoriosToFilter.filter(x => x.districtName === this.state.distrito.districtName);

          const productsFilter = territoriosToFilter.flatMap(x => x.businessLine.split(";"));
          const uniqueProductsFilter = [...new Set(productsFilter)];

          const channelTypeFilter = territoriosToFilter.map(x => x.territoryType);
          const uniqueTypesFilter = [...new Set(channelTypeFilter)];

          const filteredTerritoriesIBGE = territoriosIbge?.filter(y => territoriosToFilter.flatMap(x => x.territoryId).includes(y.territoryId)).map(z => z.codIbge);
          rows = rows.filter(x => filteredTerritoriesIBGE.includes(x.codIbge) && uniqueProductsFilter.includes(x.produtoLinha) && uniqueTypesFilter.includes(x.canalTipo));
        }
      }

      if (territoriosIbge.length > 0 && this.state.territorio !== "" && this.state.territorio != null && this.state.territorio !== undefined && (role?.accessLevel >= roleLevels?.viewDistrict)) {
        if (this.state.territorio.territoryName !== "TODOS") {
          const productsFilter = this.state.territorio.businessLine.split(";");
          const uniqueProductsFilter = [...new Set(productsFilter)];

          const channelTypeFilter = territoriosToFilter.map(x => x.territoryType);
          const uniqueTypesFilter = [...new Set(channelTypeFilter)];

          const filteredTerritoriesIBGE = territoriosIbge?.filter(y => this.state.territorio.territoryId === y.territoryId).map(z => z.codIbge);
          rows = rows.filter(x => filteredTerritoriesIBGE.includes(x.codIbge) && uniqueProductsFilter.includes(x.produtoLinha) && uniqueTypesFilter.includes(x.canalTipo));
        }
      }

      territorios = territorios.concat(territoriosToFilter);
    }

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.stocks.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div
          className="card-header"
          style={{ padding: "var(--app-card-header-padding)" }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important",
              },
            }}
          >
            <Link to={"/"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Typography color="text.primary">Estoque</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "16px" }}>
          <Stack direction={"column"}>

            {/* {(!(this.props.user.isLoadingTerritorios || this.props.user.isLoadingRole || this.props.user.isLoadingRoleLevels) && role.accessLevel > roleLevels?.viewTerritory) &&
              <Stack
                justifyContent="flex-start"
                alignItems="center"
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
                sx={{
                  backgroundColor: "#e6e8ea",
                  fontWeight: "500",
                  padding: "8px 16px !important",
                  borderRadius: "4px",
                  marginBottom: "16px"
                }}
              >
                <h6 className="mb-0">Selecione um território para filtrar a visualização dos dados:</h6>
                <ButtonSelectTerrritory variant="outlined" color={"azul"} startIcon={<EditIcon />}
                  selectedArea={this.state.area ? this.state.area : areas[0]}
                  areas={areas}
                  onChangeArea={this.onChangeArea}
                  selectedDistrito={this.state.distrito ? this.state.distrito : distritos[0]}
                  distritos={distritos}
                  onChangeDistrito={this.onChangeDistrito}
                  selectedTerritorio={this.state.territorio ? this.state.territorio : territorios[0]}
                  territorios={territorios}
                  onChangeTerritorio={this.onChangeTerritorio}
                  role={role}
                  roleLevels={roleLevels}
                >Editar Filtros</ButtonSelectTerrritory>
                {(role.accessLevel === roleLevels.viewAll) &&
                  <p className="mb-0">Area Comercial: {this.state.area ? this.state.area : areas[0]}</p>
                }
                {(role?.accessLevel >= roleLevels?.viewArea || (role?.accessLevel >= roleLevels?.viewManyDistrict && distritos.length > 2)) &&
                  <p className="mb-0">Distrito: {(this.state.distrito ? this.state.distrito : distritos[0]).districtName}</p>
                }
                {(role?.accessLevel >= roleLevels?.viewDistrict) &&
                  <p className="mb-0">Território: {(this.state.territorio ? this.state.territorio : territorios[0]).territoryName}</p>
                }
              </Stack>
            } */}

            <StockTable
              role={role?.name}
              rows={rows}
              actions={this.props.actions}
              stockProps={this.props.stocks}
              municipios={this.props.whitespace.municipios}
              msgSave={this.props.stocks.messages}
              handleSearch={this.handleSearch}
              stockPeriod={this.props.stocks.periods}
            />
          </Stack>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  whitespace: state.whitespace,
  stocks: state.stocks,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...StocksActions,
      ...whitespaceActions,
      ...clientActions,
      ...userActions
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockPage);
