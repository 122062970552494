import React from 'react';
import MuiAlert from '@mui/material/Alert';
import { NumericFormat } from "react-number-format";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    // maxWidth: 220,
    border: 'none',
  },
}));

export const ErrorTooltip = ({ messageError, maxValue, minValue, onChange, value, passStatus = false, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const [actualValue, setActualValue] = React.useState(value);

  const isAllowed = (fValue, min, max) => {
    const number = Number(fValue);
    const isOutOfBounds = number > max || number < min;
    setOpen(isOutOfBounds);

    return !isOutOfBounds;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeValue = (event) => {
    let newValue  = event.floatValue;
    newValue = isAllowed(newValue, minValue, maxValue) ? newValue : actualValue;

    setActualValue(newValue);
  }

  return (
    <HtmlTooltip
      placement="right"
      leaveDelay={600}
      open={open}
      onClose={handleClose}
      title={
        <React.Fragment>
          <Alert severity="error">
            {messageError}
          </Alert>
        </React.Fragment>
      }
    >
      <div>
        <NumericFormat
          {...props}
          value={actualValue}
          onValueChange={handleChangeValue}
          onBlur={() => passStatus ? onChange(actualValue, open) : onChange(actualValue)}
        />
      </div>
    </HtmlTooltip>
  );
}
