/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import { simulator2Constants } from "../constants/simulator2.constants";
import { simuladorSeedsConstants } from "../actions/simulatorSeeds.actions";

import { HandleResponse, history, isNullOrEmpty } from "../helpers";
import { dateEncargosActions } from "./dateencargos.action";
import { encargosActions } from "./encargos.action";
import { EncargosRatingsActions } from "./encargosrate.actions";
import { payConditionsActions } from "./paycondition.action";
import { metodoPagamentoActions } from "./metodopagamento.actions";
import { productActions } from "./products.actions";
import { simulatorSeedsActions } from "./simulatorSeeds.actions";
import { Simulador2ComboActions } from "./simulator2Combo.actions";

export const ClientActions = {
  requestClients: () => async (dispatch, getState) => {
    const { oidc, simulator2 } = getState();
    const { clientes } = simulator2;
    if (clientes?.clients?.length <= 0) {
      dispatch({ type: simulator2Constants.requestClientsType });

      // {* const url = `/api/analyticsclient`; *}
      const url = `/api/analyticsclientv2`;

      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      const clientList = await response.json();
      dispatch({
        type: simulator2Constants.receiveClientsType,
        clients: clientList,
      });
    }
  },
  requestClient: (id) => async (dispatch, getState) => {
    const { oidc, simulator2 } = getState();
    const { clientes } = simulator2;
    if (clientes?.client?.id != id) {
      dispatch({ type: simulator2Constants.requestClientType });

      const url = `/api/analyticsclientv2/client/${id}`;

      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      const client = await response.json();
      dispatch({ type: simulator2Constants.receiveClientType, client: client });
    }
  },
};

export const GetCenarioState = (getState) => {
  const remapedSections = getState().simuladorSeeds?.sections?.map(
    (section) => ({
      ...section,
      rows: section.rows.map((row) => ({
        ...row,
        sufixo: row?.sufixo?.sufixo,
        sufixos: [],
      })),
      descontos: [],
      campanha: undefined,
      analyticsProducts: [],
      produtos: [],
    })
  );

  var rows = getState().simulator2.simulators;
  const simulatorsData = getState().simulator2.simulatorsData;
  rows = rows.map((x, index) => {
    x.ordem = index;
    return x;
  });

  return {
    isCpActive: simulatorsData.isCpActive,
    cenarioV2Cp:
      simulatorsData.isCpActive && simulatorsData.initCp
        ? {
            ...simulatorsData.cenarioV2Cp,
            itensCenarios: rows,
          }
        : undefined,
    isSeedsActive: simulatorsData.isSeedsActive,
    cenarioV2Seeds: simulatorsData.isSeedsActive ? remapedSections : undefined,
  };
};

export const CenarioActions = {
  createCenario: () => async (dispatch, getState) => {
    const { oidc } = getState();

    const clienteId = getState().simulator2.clientes.client.id;

    const body = GetCenarioState(getState);

    dispatch({ type: simulator2Constants.requestCreateCenarioType });

    const url = `/api/cenarioV2?clienteId=${clienteId}`;

    const options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      (SaveCenario) => {
        sessionStorage.setItem("cenarioId", SaveCenario);
        dispatch({
          type: simulator2Constants.changeDataValue,
          name: "id",
          value: SaveCenario,
        });

        history.push(
          `/Simulador2/Simulador-Cliente-Edit?GroupId=${clienteId}&CenarioId=${SaveCenario}`
        );
        dispatch({
          type: simulator2Constants.receiveCreateCenarioType,
          SaveCenario,
        });
      },
      (error) => {
        dispatch({
          type: "CALL_ALERT",
          title: "Não foi possível criar o cenário.",
          message: error,
          responseType: "error",
        });
      }
    );
  },
  SaveCenarioAndClear:
    (finalizado = false) =>
    async (dispatch, getState) => {
      const { oidc } = getState();

      const cenarioId = getState().simulator2.simulatorsData.id;
      const clienteId = getState().simulator2.clientes.client.id;

      const body = GetCenarioState(getState);

      dispatch({ type: simulator2Constants.requestSaveCenarioType });

      const url = `/api/cenarioV2/${cenarioId}?finalizado=${finalizado}`;

      const options = {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);

      HandleResponse(response).then(
        (SaveGenerateCenario) => {
          dispatch({
            type: simulator2Constants.receiveSaveCenarioType,
            SaveGenerateCenario,
          });
        },
        (error) => {
          dispatch({
            type: "CALL_ALERT",
            title: "Não foi possível salvar o cenário.",
            message: error,
            responseType: "error",
          });
        }
      );

      dispatch(simulatorActions.removeAllRows());
      dispatch(CenarioActions.requestCenarios(clienteId));
    },
  requestCenarios: (clientId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: simulator2Constants.requestCenariosType });

    const url = `/api/cenarioV2?clienteId=${clientId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const listCenarios = await response.json();
    dispatch({ type: simulator2Constants.receiveCenarioslType, listCenarios });
  },
  deleteCenario:
    (id, func, arg1 = "") =>
    async (dispatch, getState) => {
      const { oidc } = getState();

      dispatch({ type: simulator2Constants.deletarRequestCenarioslType });

      const url = `/api/cenarioV2/${id}`;

      const options = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      await response.json();
      dispatch(func(arg1));
      dispatch({ type: simulator2Constants.deletarCompleteCenarioslType });
    },
  cancelCenario:
    (id, motivo, func, arg1 = "") =>
    async (dispatch, getState) => {
      const { oidc } = getState();

      dispatch({ type: simulator2Constants.cancelarRequestCenarioslType });

      const url = `/api/Simulador2/workflow/cancelar/${id}`;
      const options = {
        method: "PATCH",
        body: JSON.stringify(motivo),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      await response.json();
      dispatch(func(arg1));
      dispatch({ type: simulator2Constants.cancelarCompleteCenarioslType });
    },
};

function GetComboActive(combos, rowsProducts) {
  Object.keys(combos).forEach((comboKey) => {
    const combo = combos[comboKey];

    const { baseProducts, otherProducts } = combo.products.reduce(
      (acc, product) => {
        const key = product.baseProduct ? "baseProducts" : "otherProducts";
        acc[key].push({
          productId: product.productId,
          minVolume: product.minVolume,
        });
        return acc;
      },
      { baseProducts: [], otherProducts: [] }
    );

    const remmapedRows = rowsProducts.map((x) => x.product);
    const baseProductsInRows = baseProducts.filter((product) => {
      const produto = rowsProducts.find((x) => x.product == product.productId);
      return produto !== undefined && produto?.volume >= product.minVolume;
    });
    const otherProductsInRows = otherProducts.filter((product) => {
      const produto = rowsProducts.find((x) => x.product == product.productId);
      return produto !== undefined && produto?.volume >= product.minVolume;
    });

    combo.active =
      baseProducts.length > 0 &&
      baseProducts.every((product) => remmapedRows.includes(product.productId));
    combo.amount = baseProductsInRows.length + otherProductsInRows.length;
  });

  return combos.filter((x) => x.active);
}

const CreateRow = (
  produto,
  cliente,
  descontoCategoria,
  rate,
  moeda,
  analytics = false
) => {
  descontoCategoria ??= 0;
  const priceEncargo = (produto.price ?? 0) * (1 + rate / 100);
  const finalPrice = priceEncargo - priceEncargo * (descontoCategoria / 100);

  return {
    produto: produto.product,
    analytics: analytics,
    premium: produto.premium == undefined ? false : produto.premium,
    moeda: moeda,
    preco: produto.price ?? 0,
    encargos: rate,
    dose: produto.dose ?? 0,
    descontoCategoriaMax: descontoCategoria / 100,
    descontoCategoria: 0,
    descontoComboMax: 0,
    descontoCombo: 0,
    precoProposto: 0,
    volume: 0,
    precoNegociado: 0,
    hectaresTratados: 0,
    msMin: produto.perMS ?? 0,
    areaSoja: cliente.areaPlantadaSoja ?? 0,
    validPremium: false,
    valorFinalCorteva: finalPrice,
    cpa: -finalPrice,
    valorFinal: 0,
    cur: produto.cur ?? 0,
    cogs: produto.cogs ?? 0,
    salesDeductionPer: produto.salesDeduction ?? 0,
    gmTarget: produto.gmTarget ?? 0,
    cpaMax: produto.perCpaMax ?? 0,
    valorFinalNegociado: 0,
    motivo: produto.motivo ?? "",
    descontoOfertaEstruturada: 0,
    frb: produto.frb,
    ultimaNegociacaoUsd: produto.ultimaNegociacaoUsd ?? 0,
    ultimaNegociacaoBrl: produto.ultimaNegociacaoBrl ?? 0,
    recomendacaoMarketing: produto.recomendacaoMarketing ?? false,
    geracaoDemanda: produto.geracaoDemanda ?? false,
    guiaPosicionamento: produto.guiaPosicionamento ?? false,
    historico: produto.historico ?? false,
    novoProduto: produto.novoProduto ?? false,
    salesInput: produto.salesInput ?? 0,
    cota: produto.cota ?? 0,
    tipoIncentivo: produto.tipoIncentivo ?? 0,
    incentivo: produto.incentivo ?? 0,
  };
};

export const simulatorActions = {
  requestItensById2:
    (groupId, cenarioId, clone) => async (dispatch, getState) => {
      const { oidc } = getState();

      dispatch({ type: simulator2Constants.requestItensCenario });
      dispatch({ type: "REQUEST_GetCenario" });

      const url = `/api/cenarioV2/edit/${cenarioId}?clone=${clone}`;

      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      HandleResponse(response).then(
        (cenario) => {
          history.push(
            `/Simulador2/Simulador-Cliente-Edit?GroupId=${groupId}&CenarioId=${cenario.id}`
          );
          sessionStorage.setItem("cenarioId", cenario.id);
          dispatch({
            type: simulator2Constants.changeDataValue,
            name: "id",
            value: cenario.id,
          });
          dispatch({
            type: simulator2Constants.changeDataValue,
            name: "isCpActive",
            value: cenario.isCpActive ?? true,
          });
          dispatch({
            type: simulator2Constants.changeDataValue,
            name: "isSeedsActive",
            value: cenario.isSeedsActive ?? true,
          });

          if (cenario.isCpActive ?? true) {
            if (
              cenario.cenarioV2Cp?.campanha != "" &&
              cenario.cenarioV2Cp?.campanha != undefined &&
              cenario.cenarioV2Cp?.campanha != null
            ) {
              dispatch({
                type: simulator2Constants.changeDataValue,
                name: "initCp",
                value: true,
              });

              dispatch(
                encargosActions.requestencargos2(cenario.cenarioV2Cp.campanha)
              );
              dispatch(
                payConditionsActions.requestPayConditions2(
                  cenario.cenarioV2Cp.campanha
                )
              );
              dispatch(
                Simulador2ComboActions.requestSimuladorCombo(
                  cenario.cenarioV2Cp.campanha
                )
              );
              dispatch(
                dateEncargosActions.requestdateEncargos2(
                  cenario.cenarioV2Cp.campanha,
                  cenario.cenarioV2Cp.condicao
                )
              );
              dispatch(
                EncargosRatingsActions.requestEncargosRatings2(
                  cenario.cenarioV2Cp.condicao,
                  cenario.cenarioV2Cp.vencimento,
                  cenario.cenarioV2Cp.campanha
                )
              );
              dispatch(
                metodoPagamentoActions.requestMetodoPagamento(
                  cenario.cenarioV2Cp.campanha,
                  cenario.cenarioV2Cp.metodoPagamento
                )
              );
              dispatch(
                productActions.requestProducts2(
                  cenario.cenarioV2Cp.campanha,
                  groupId
                )
              );

              dispatch({
                type: simulator2Constants.changeDataValue,
                name: "moeda",
                value: cenario.cenarioV2Cp.moeda,
              });

              dispatch({
                type: simulator2Constants.changeDataCpValue,
                name: "campanha",
                value: cenario.cenarioV2Cp.campanha,
              });
              dispatch({
                type: simulator2Constants.changeDataCpValue,
                name: "condicao",
                value: cenario.cenarioV2Cp.condicao,
              });
              dispatch({
                type: simulator2Constants.changeDataCpValue,
                name: "vencimento",
                value: cenario.cenarioV2Cp.vencimento,
              });
              dispatch({
                type: simulator2Constants.changeDataCpValue,
                name: "metodoPagamento",
                value: cenario.cenarioV2Cp.metodoPagamento,
              });
              dispatch({
                type: simulator2Constants.changeDataCpValue,
                name: "maxComboBarter",
                value: cenario.cenarioV2Cp.maxComboBarter,
              });
              dispatch({
                type: simulator2Constants.changeDataCpValue,
                name: "valorizacaoBarter",
                value: cenario.cenarioV2Cp.valorizacaoBarter,
              });
              dispatch({
                type: simulator2Constants.changeDataCpValue,
                name: "bonusAdimplencia",
                value: cenario.cenarioV2Cp.bonusAdimplencia,
              });
              dispatch({
                type: simulator2Constants.changeDataCpValue,
                name: "descontoOfertaEstruturada",
                value: cenario.cenarioV2Cp.descontoOfertaEstruturada,
              });

              dispatch({
                type: simulator2Constants.receiveItensCenario,
                ItensCenario: cenario.cenarioV2Cp.itensCenarios,
              });

              if (cenario.cenarioV2Cp.itensCenarios.length > 0) {
                dispatch({
                  type: simulator2Constants.changeDataValue,
                  name: "hasData",
                  value: true,
                });
              }
            } else {
              dispatch({
                type: simulator2Constants.receiveItensCenario,
                ItensCenario: [],
              });
            }
          } else {
            dispatch({
              type: simulator2Constants.receiveItensCenario,
              ItensCenario: [],
            });
          }

          if (cenario.isSeedsActive ?? true) {
            const encargos = getState().encargos.allEncargos;
            dispatch(
              simulatorSeedsActions.handleLoadAndCalc(cenario, encargos)
            );
          } else {
            dispatch({
              type: simuladorSeedsConstants.RECEIVE_SEED_CENARIO_SECTIONS,
              sections: [],
            });
          }
        },
        (error) => {
          dispatch({
            type: "CALL_ALERT",
            title: "Não foi possível carregar os dados do cenário.",
            message: error,
            responseType: "error",
          });
        }
      );
    },
  handleChange: (e, rowId, produto, currency) => async (dispatch, getState) => {
    const { name, value } = e.target;
    dispatch({
      type: simulator2Constants.changeValue,
      name,
      value,
      rowId,
      produto,
      currency,
    });

    if (name == "volume") {
      dispatch(simulator2Actions.findProductsCombo());
    }
  },
  attDiscount:
    (campaign, descontoOfertaEstruturada, descExalt, descUtrisha, currency) =>
    async (dispatch) => {
      dispatch({
        type: simulator2Constants.attAllDiscount,
        campaign: campaign,
        descontoOfertaEstruturada: descontoOfertaEstruturada,
        descExalt: descExalt,
        descUtrisha: descUtrisha,
        currency: currency,
      });
    },
  handleChangeCondition: (conditions) => async (dispatch) => {
    dispatch({
      type: simulator2Constants.changeCondition,
      conditions: conditions,
    });
  },
  removeRow:
    (e, rowId, produto, motivo = "") =>
    async (dispatch) => {
      dispatch({
        type: simulator2Constants.REMOVE_ROW,
        rowId,
        produto,
        motivo,
      });

      dispatch(simulator2Actions.findProductsCombo());
    },
  removeAllRows: () => async (dispatch) => {
    dispatch({ type: simulator2Constants.REMOVE_ALL_ROW });
    dispatch({ type: simulator2Constants.clearData });
    dispatch({ type: simuladorSeedsConstants.CLEAR_SEEDS_DATA });

    dispatch(simulator2Actions.findProductsCombo());
  },
  addRowAnalyticsProducts:
    (produtos, rate, cliente, moeda) => async (dispatch, getState) => {
      if (produtos && cliente) {
        if (produtos.length > 0) {
          const simulatorsData = getState().simulator2.simulatorsData;
          if (!simulatorsData.hasData)
            dispatch({
              type: simulator2Constants.changeDataValue,
              name: "hasData",
              value: true,
            });

          for (var i = 0; i < produtos.length; i++) {
            const row = CreateRow(
              produtos[i],
              cliente,
              produtos[i].descontoCategoria,
              rate,
              moeda,
              true
            );

            dispatch({
              type: simulator2Constants.ADD_ROW,
              row: row,
            });
          }
        }
      }
    },
  addRow:
    (produto, cliente, encargosRate, moeda) => async (dispatch, getState) => {
      if (produto && cliente) {
        const simulatorsData = getState().simulator2.simulatorsData;
        if (!simulatorsData.hasData)
          dispatch({
            type: simulator2Constants.changeDataValue,
            name: "hasData",
            value: true,
          });

        const rate = encargosRate.rate ?? 0;
        const row = CreateRow(
          produto,
          cliente,
          produto.descontoCategoria,
          rate,
          moeda
        );

        dispatch({
          type: simulator2Constants.ADD_ROW,
          row: row,
        });
      }
    },
  updateRows:
    (produtos, currency, oldCurrency) => async (dispatch, getState) => {
      dispatch({
        type: simulator2Constants.UPDATE_ALL,
        produtos: produtos,
        currency: currency,
        oldCurrency: oldCurrency,
      });

      dispatch(simulator2Actions.findProductsCombo());
    },
  handleChangeSimulatorData: (name, value) => async (dispatch, getState) => {
    dispatch({ type: simulator2Constants.changeDataValue, name, value });
  },
  handleChangeSimulatorDataCp: (name, value) => async (dispatch, getState) => {
    dispatch({ type: simulator2Constants.changeDataCpValue, name, value });
  },
  findProductsCombo: () => async (dispatch, getState) => {
    const combos = getState().simulador2Combo?.combos ?? [];
    const rowsProducts =
      getState()
        ?.simulator2?.simulators?.filter(
          (x) => x.volume > 0 && isNullOrEmpty(x.excluido)
        )
        .map((x) => ({ product: x.produto.toLowerCase(), volume: x.volume })) ??
      [];

    const activeCombos = GetComboActive(combos, rowsProducts);
    if (!isNullOrEmpty(activeCombos))
      dispatch(Simulador2ComboActions.getCombo(activeCombos));
    else dispatch(Simulador2ComboActions.cleanCombo(true));
  },
  updateRowsCombo: () => async (dispatch, getState) => {
    const activeCombos = getState().simulador2Combo?.activeCombos;
    const combos = getState().simulador2Combo?.combosParameters;
    const rowsProducts = getState().simulator2.simulators;
    var maxBarterDsc = undefined;

    rowsProducts?.forEach((row, i) => {
      let dscCombo = 0;
      if (!isNullOrEmpty(activeCombos) && !isNullOrEmpty(combos)) {
        const matchingCombos = activeCombos
          .filter((combo) =>
            combo.products?.some(
              (product) =>
                product.productId.toLowerCase() === row.produto.toLowerCase()
            )
          )
          .map((x) => x.comboIdentity);

        const matchingParameters = combos.filter((x) =>
          matchingCombos.includes(x.comboIdentity)
        );

        dscCombo =
          matchingCombos.length > 0
            ? Math.max(
                0,
                ...matchingParameters.map((combo) => combo.maxDsc ?? 0)
              )
            : 0;

        const barterDsc =
          matchingCombos.length > 0
            ? Math.max(
                0,
                ...matchingParameters.map((combo) => combo.maxBarter ?? 0)
              )
            : 0;

        if (maxBarterDsc === undefined || maxBarterDsc < barterDsc)
          maxBarterDsc = barterDsc;
      }

      if (dscCombo < row.descontoCombo) {
        dispatch(
          simulator2Actions.handleChange(
            { target: { name: "descontoCombo", value: Number(dscCombo) } },
            i,
            row.produto,
            row.currency
          )
        );
      }

      dispatch(
        simulator2Actions.handleChange(
          { target: { name: "descontoComboMax", value: Number(dscCombo) } },
          i,
          row.produto,
          row.currency
        )
      );
    });

    dispatch({
      type: simulator2Constants.changeDataCpValue,
      name: "maxComboBarter",
      value: maxBarterDsc,
    });

    if (
      maxBarterDsc !== undefined &&
      getState().simulator2.simulatorsData.cenarioV2Cp.valorizacaoBarter >
        maxBarterDsc
    )
      dispatch({
        type: simulator2Constants.changeDataCpValue,
        name: "valorizacaoBarter",
        value: 0,
      });
  },
};

export const decontosActions = {
  requestDescontoPremium: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: simulator2Constants.requestDescontoPremiumCpType });

    const url = `/api/DescontoPremiumCp`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.status == 200) {
      const desconto = await response.json();
      dispatch({
        type: simulator2Constants.receiveDescontoPremiumCpType,
        desconto,
      });
    }
  },
  requestDescontoSoc: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: simulator2Constants.requestDescontoSocCpType });

    const url = `/api/DescontoSocCp`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.status == 200) {
      const desconto = await response.json();
      dispatch({
        type: simulator2Constants.receiveDescontoSocCpType,
        desconto,
      });
    }
  },
};

export const SemaforoActions = {
  requestNivelSemaforoFlexibilidade: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({
      type: simulator2Constants.requestNivelSemaforoFlexibilidadeType,
    });

    const url = `/api/NivelSemaforoV2/Flexibilidade`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.status == 200) {
      const nivel = await response.json();
      dispatch({
        type: simulator2Constants.receiveNivelSemaforoFlexibilidadeType,
        nivel,
      });
    }
  },
  requestNivelSemaforoGmTarget: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: simulator2Constants.requestNivelSemaforoGmTargetType });

    const url = `/api/NivelSemaforoV2/GmTarget`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.status == 200) {
      const nivel = await response.json();
      dispatch({
        type: simulator2Constants.receiveNivelSemaforoGmTargetType,
        nivel,
      });
    }
  },
};

export const CurActions = {
  getCur: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: simulator2Constants.requestCur });

    const url = `/api/SimuladorCurV2`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);

    if (response.status == 200) {
      const value = await response.json();
      dispatch({ type: simulator2Constants.receiveCur, value });
    } else {
      dispatch({ type: simulator2Constants.failureCur });
    }
  },
};

export const simulator2Actions = {
  ...ClientActions,
  ...CenarioActions,
  ...simulatorActions,
  ...decontosActions,
  ...SemaforoActions,
  ...CurActions,
};
