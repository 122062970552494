/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */

import { simulator2Actions } from "./simulator2.actions";

const REQUESTSIMULADORCOMBO = "REQUEST_SIMULADOR_COMBO";
const RECEIVESIMULADORCOMBO = "RECEIVE_SIMULADOR_COMBO";

const REQUESTCOMBOPARAMETERS = "REQUEST_COMBO_PARAMETERS";
const RECEIVECOMBOPARAMETERS = "RECEIVE_COMBO_PARAMETERS";

const CLEANCOMBOPARAMETERS = "CLEAN_COMBO_PARAMETERS";

const initialState = {
  isLoading: false,
  combos: [],
  combosParameters: [],
  activeCombos: [],
};

export const Simulador2ComboActions = {
  requestSimuladorCombo: (campaignId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: REQUESTSIMULADORCOMBO });

    const url = `/api/simuladorProdutoCombo/${campaignId}`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const combos = await response.json();
    dispatch({
      type: RECEIVESIMULADORCOMBO,
      combos: combos,
    });
  },
  getCombo: (combos) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: REQUESTCOMBOPARAMETERS, combos });

    for (const combo of combos) {
      const url = `/api/simuladorProdutoCombo/Combo/${combo.comboIdentity}/${combo.amount}`;
      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      try {
        const response = await fetch(url, options);
        const comboParameters = await response.json();
        dispatch({
          type: RECEIVECOMBOPARAMETERS,
          comboParameters: comboParameters,
        });
      } catch (error) {
        console.error("Error fetching combo parameters:", error);
      }
    }

    dispatch(simulator2Actions.updateRowsCombo());
  },
  cleanCombo:
    (cleanProducts = false) =>
    async (dispatch) => {
      dispatch({
        type: CLEANCOMBOPARAMETERS,
        activeCombos: [],
        combosParameters: [],
      });

      if (cleanProducts) {
        dispatch(simulator2Actions.updateRowsCombo());
      }
    },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUESTSIMULADORCOMBO:
      return {
        ...state,
        isLoading: true,
      };
    case RECEIVESIMULADORCOMBO:
      return {
        ...state,
        isLoading: false,
        combos: action.combos,
      };
    case REQUESTCOMBOPARAMETERS:
      return {
        ...state,
        isLoading: true,
        activeCombos: action.combos,
        combosParameters: [],
      };

    case RECEIVECOMBOPARAMETERS:
      return {
        ...state,
        isLoading: false,
        combosParameters: [...state.combosParameters, action.comboParameters],
      };
    case CLEANCOMBOPARAMETERS:
      return {
        ...state,
        activeCombos: [],
        combosParameters: [],
      };
    default:
      return state;
  }
};
